import { useState, useContext, useEffect} from "react";
import { Link } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  faFileInvoice,
  faUserGroup,
  faBoxesPacking,
  faUserFriends,
  faUserTie,
  faDollar,
  faRedo,
  faVanShuttle,
  faStore,
  faImage
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {AppStorage} from '../Utils/Context/AppContext'
import { useTranslation } from "react-i18next";



function SideBarList({open}) {
  const [navItems, setNavItems] = useState([]);
  const AppContext = useContext(AppStorage);
  const { t, i18n } = useTranslation();

  const links = [
    
    {
      title: `${t("Invoices")}`,
      path: "/invoices",
      access: ["admin", "user", "sub-admin"],
      icon: faFileInvoice,
    },
    {
      title: "Users",
      path: "/allUsers",
      access: ["admin", "sub-admin"],
      icon: faUserGroup,
    },
    {
      title: "Sub Admin",
      path: "/allSubAdmin",
      access: ["admin"],
      icon: faUserTie,
    },
    {
      title: `${t("Clients")}`,
      path: "/allClients",
      access: ["user"],
      icon: faUserFriends,
    },
    {
      title: `${t("Products")}`,
      path: "/allProducts",
      access: ["user"],
      icon: faBoxesPacking,
    },
    {
      title: `${t("Price Offer")}`,
      path: "/allOfferPrice",
      access: ["user"],
      icon: faDollar,
    },
    {
      title: `${t("All Sales Return")}`,
      path: "/allSalesReturn",
      access: ["user"],
      icon: faRedo,
    },
    {
      title: `${t("All Suppliers")}`,
      path: "/allSuppliers",
      access: ["user"],
      icon: faVanShuttle,
    },
    {
      title: `${t("All Purchases")}`,
      path: "/allSupplierInvoice",
      access: ["user"],
      icon: faStore,
    },
    {
      title: `${t("Design Template")}`,
      path: "/templateList",
      access: ["admin"],
      icon: faImage,
    }
  ];

  useEffect(() => {
    if (AppContext?.userInfo) {
      if (AppContext?.userInfo.role == "user") {
        setNavItems(links.filter((elem) => elem.access.includes("user")));
      } else if (AppContext?.userInfo.role == "admin") {
        setNavItems(links.filter((elem) => elem.access.includes("admin")));
      } else if (AppContext?.userInfo.role == "sub-admin") {
        setNavItems(links.filter((elem) => elem.access.includes("sub-admin")))
      } else {
        setNavItems(links);
      }
    }
  }, [AppContext?.userInfo, i18n.language]);

  return (
    <>
      {navItems.map((link, i) => (
        <ListItem key={i} disablePadding sx={{ display: "block" }}>
          <Link to={link.path} key={link.title}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 4 : "auto",
                  justifyContent: "center",
                }}
              >
                <FontAwesomeIcon className="sidebar-icon" icon={link.icon} />
              </ListItemIcon>
              <ListItemText
                primary={link.title}
                sx={{ opacity: open ? 1 : 0}}
              />
            </ListItemButton>
          </Link>
        </ListItem>
      ))}
    </>
  );
}

export default SideBarList;
