import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Request from "../Utils/Request/Request";
import { useState, useContext, useEffect, useRef } from "react";
import { AppStorage } from "../Utils/Context/AppContext";
import { showToast } from "../Utils/index";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PdfIcon from "@mui/icons-material/PictureAsPdf";
import Button from "@mui/material/Button";
import DeleteModal from "./DeleteModal";
import { useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import moment from "moment";
import { FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import Loader from "./Loader";
import DialogProducts from "./DialogProducts";
import { useTranslation } from "react-i18next";

function AllSalesReturn() {
  const navigate = useNavigate();
  const [openInvoiceProducts, setOpenInvoiceProducts] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const { t, i18n } = useTranslation();
  const handleOpenInvoiceProducts = (value) => {
    setOpenInvoiceProducts(true);
    setSelectedItem(value);
  };

  const handleCloseInvoiceProducts = () => {
    setOpenInvoiceProducts(false);
  };

  let date = new Date();
  date.setMonth(date.getMonth() - 1);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const [invoices, setInvoices] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [tableColumns, setTableColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(new Date());
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 7,
  });
  const [searchPhrase, setSearchPhrase] = useState("");


  const AppContext = useContext(AppStorage);

  
  useEffect(() => {
    if(AppContext?.userInfo.role &&  AppContext?.userInfo.role != "user") {
      setTableColumns(columns.concat(userColumn, actionColumn));
    } else {
      setTableColumns(columns.concat(actionColumn));
    }
    if (AppContext?.userAuthToken || AppContext?.userAuthToken !== "") {
      getData({ pagination, searchPhrase });
    }
  }, [AppContext?.userAuthToken, pagination?.current, AppContext?.userInfo, i18n.language]);

  useEffect(() => {
    if (AppContext?.userInfo?.role === "admin") {
      i18n.changeLanguage("en");
    }
  },[AppContext?.userAuthToken, pagination?.current, AppContext?.userInfo, i18n.language])
  
  useEffect(() => {
    if (forceUpdate) {
      getData({ pagination, searchPhrase, startDate, endDate });
    }
  }, [forceUpdate]);


  
  
  function getData(params) {
    setLoading(true);
    let paramsData = {};

    paramsData = {
      ...paramsData,
      startDate: params?.dateStart || moment.utc(moment(startDate).format().substring(0, 10)).format(),
      endDate: params?.dateEnd || moment.utc(moment(endDate).format().substring(0, 10)).format(),
      searchPhrase: params.searchPhrase,
      salesReturn: true
    };

    Request.get(`/getInvoice/${params.pagination?.current}`, paramsData)
      .then((result) => {
        setLoading(false);
        setInvoices(result.data["data"]);
        console.log(result.data["data"]);
        setForceUpdate(false);
        setPagination({
          ...params.pagination,
          total: result.data["totalCount"],
        });
        setSearchPhrase(params.searchPhrase);
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }

  const handleSelect = (date) => {
    const dateStart =  moment.utc(moment(date.selection.startDate).format().substring(0, 10)).format();
    const dateEnd = moment.utc(moment(date.selection.endDate).format().substring(0, 10)).format();
   
    setStartDate(date.selection.startDate);
    setEndDate(date.selection.endDate);
    
    getData({ pagination, dateStart, dateEnd });
  };
  const handleChange = (event, value) => {
    let dupPagination = { ...pagination };
    dupPagination.current = value;
    setPagination(dupPagination);
  
  };
  function searchChange(value) {
    setSearchPhrase(value)
    setPagination({ ...pagination, current: 1 });
    getData({ pagination: { ...pagination, current: 1 }, searchPhrase: value });
  }

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };

  // Function to recalculate row numbers
  const updateRowNumbers = () => {
    const invoicesWithRowNumber = invoices.map((invoice, index) => {
      return { ...invoice, rowNumber: (pagination.current - 1) * 7 + (index + 1) };
    });
    setInvoices(invoicesWithRowNumber);
  };

//   // Function to handle row deletion
//   const handleRowDelete = (selectedRowIds) => {
//     const updatedInvoices = invoices.filter(
//       (invoice) => !selectedRowIds.includes(invoice.id)
//     );
//     setInvoices(updatedInvoices);
//     updateRowNumbers(); // Recalculate row numbers after deletion
//   };

  const columns = [
    { field: "rowNumber", headerName: `${t('SR-NO.')}`, width: 70 },
    { field: "invoiceNumber", headerName: `${t('Invoice No.')}`, width: 90 },
    { field: "companyName", headerName: `${t("Client")}`, width: 200 },
    { field: "vatNumber", headerName: `${t("Vat Number")}`, width: 100 },
    { field: "total", headerName: `${t("Total")}`, width: 120 },
    {
      field: "invoiceproducts",
      headerName: `${t("No. of Products")}`,
      renderCell: (text) => {
        return (
          <div>
            <Button
              variant="outlined"
              onClick={() => handleOpenInvoiceProducts(text)}
            >
              {text?.value?.length}
            </Button>
          </div>
        );
      },
      width: 150,
    },
    {
      field: "invoiceDate",
      headerName: `${t("Invoice Date")}`,
      renderCell: (text) => (
        <span>{moment.utc(text?.value).format("YYYY-MM-DD")}</span>
      ),
      width: 130,
    },
  ];

  let actionColumn = [
    {
      headerName: `${t("Actions")}`,
      renderCell: (record) => {
        
        return (
          <Box sx={{ display: "flex", gap: "8px" }}>
            {(AppContext?.userInfo.role === "admin" || AppContext?.userInfo.role === "user") &&(
                <PdfIcon
                  sx={{ cursor: "pointer", color: "#FF0000" }}
                  onClick={() => printInvoice(record.row)}
                  />
            )} 
             
          </Box>
        );
      },
    },
  ];

  let userColumn = [
    { 
      field: "user", headerName: "User Name",
      renderCell: (text) => {
        return (
          <div> {text.value.fullName}</div>
        );
      },
      width: 70
    },
  ]

  function printInvoice(invoice) {
    window.open(`/invoice/${invoice.id}`, "_blank")?.focus();
  }
  


//   function deleteInvoice(invoice) {
//     Request.delete(`/delInvoice/${invoice["id"]}`)
//       .then(() => {
//         setForceUpdate(true);
//         CloseDelModal();
//       })
//       .catch((err) => {
//         console.log("Error: ", err);
//         if (err.response.status == 401) {
//           showToast("error", "Unauthorized user.");
//         } else {
//           showToast("error", "Something went wrong.");
//         }
//       });
//   }

  return (
    <Box>
   
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          width: "100%",
          marginBottom: "0px",
        }}
      >
        <Box sx={{display:"flex"}}>
        <Box
          onMouseOver={() => setShowDatePicker(true)}
          onMouseOut={() => setShowDatePicker(false)}
          sx={{
            width: "332px",
            position: "relative",
            "& .rdrDateRangePickerWrapper": {
              width: "100%",
              "& .rdrCalendarWrapper ": {
                width: "100%",
              },
            },
            "& .rdrMonthAndYearWrapper": {
              position: "absolute",
              top: 70,
              background: "white",
              width: "100%",
              display: showDatePicker ? "" : "none",
              zIndex: 999,
              boxShadow: "0px 3px 8px rgb(100,100,100)",
            },
            "& .rdrMonths ": {
              position: "absolute",
              top: 130,
              background: "white",
              display: showDatePicker ? "" : "none",
              zIndex: 999,
              boxShadow: "0px 3px 8px rgb(100,100,100)",
            },
          }}
        >
          <DateRangePicker ranges={[selectionRange]} onChange={handleSelect} />
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            type="text"
            autoComplete="off"
            placeholder= {t("Search Company/Invoice NO.")}
            onChange={(event) => searchChange(event.target.value)}
            sx={{
              width: "250px",
              marginRight: "20px",
              "& .MuiOutlinedInput-input": { height: "1em" },
              "& fieldset": {
                borderColor: "#365b6d !important",
              },
            }}
          />
        </Box>
        </Box>
      </Box>

      <div style={{ height: "calc(100vh - 235px)", width: "100%" }}>
        <Typography variant="h6" sx={{ color: "#365b6d", textAlign: "center" }}>
          {t("All Sales Return")}
        </Typography>
        {loading ? (
          <Loader />
        ) : (
          <>
            <DataGrid
              // rows={invoices}
              rows={invoices.map((invoice, index) => ({
                ...invoice,
                rowNumber: (pagination.current - 1) * 7 + (index + 1),
              }))}
              columns={tableColumns}
              disableColumnMenu
              hideFooter
              checkboxSelection={false}
              disableSelectionOnClick
              sx={{ background: "white" }}
            />
            <Stack spacing={2} marginTop="5px">
              <Pagination
                count={Math.ceil(pagination?.total / 7)}
                page={pagination?.current}
                onChange={handleChange}
              />
            </Stack>
          </>
        )}
      </div>
      <DialogProducts
        handleCloseInvoiceProducts={handleCloseInvoiceProducts}
        openInvoiceProducts={openInvoiceProducts}
        text={selectedItem}
      />
    </Box>
  );
}

export default AllSalesReturn;
