import React, { useState, useContext, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/material";
import Request from "../Utils/Request/Request";
import { showToast } from "../Utils/index";
import { AppStorage } from "../Utils/Context/AppContext";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";

function ProductModal({ close, open, product, setForceUpdate }) {
  const [productName, setProductName] = useState("");
  const [validated, setValidated] = useState(true);
  const { t, i18n } = useTranslation();
  const AppContext = useContext(AppStorage);
  useEffect(() => {
    if (product) {
      setProductName(product["name"]);
    }
  }, []);

  const validateFormData = () => {
    if (productName === "") {
      setValidated(false);
      return false;
    }
    setValidated(true);
    return true;
  };

  const handleSubmit = () => {
    if (validateFormData()) {
      let tempData = { name: productName };
      if (product == null) {
        Request.post("/createProduct", tempData)
          .then((result) => {
            toast.success("New Product Added")
            setTimeout(() => {
              close();
              setForceUpdate(true);
            }, 1500);
            console.log("Data: ", result.data);
          })
          .catch((err) => {
            console.log("Error: ", err);
            if (err.response.status == 401) {
              showToast('error', 'Unauthorized user.');
            } else {
              showToast('error', 'Something went wrong.');
            }
          });
      } else {
        tempData["id"] = product["id"];
        Request.put("/updateProduct", tempData)
          .then((result) => {
            console.log("Data: ", result.data);
            toast.success("Product Updated");
            setTimeout(() => {
              close();
              setForceUpdate(true);
            }, 1500);
          })
          .catch((err) => {
            console.log("Error: ", err);
            if (err.response.status == 401) {
              showToast("error", "Unauthorized user.");
            } else {
              showToast("error", "Something went wrong.");
            }
          });
      }
    }
  };
  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>
        {product === null ? `${t("Add New Product")}` : `${t("Update Product")}`}
      </DialogTitle>
      <DialogContent sx={{maxWidth: "600px" }}>
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <Box>
            <TextField
              label={t("Product Name")}
              size="small"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              sx={{
                // textAlign:"center",
                "& .MuiInputLabel-shrink": {
                  color: "#41c1ba !important",
                },
                "& fieldset": {
                  borderColor: "#41c1ba !important",
                  "& legend": {
                    "& span": {
                      color: "#41c1ba !important",
                    },
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#41c1ba",
                },
                width: "200px"
              }}
            />
            {!validated &&
              (productName === null || productName === "") && (
                <div style={{ color: "red", fontSize: "small" }}>
                  Name of Product is required
                </div>
              )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>{t("Cancel")}</Button>
        <Button
          variant="contained"
          sx={{
            background: "#365b6d",
            "&:hover": { backgroundColor: "#365b6d" },
          }}
          onClick={handleSubmit}
        >
          {product === null ? `${t("Add New Product")}` : `${t("Update Product")}`}
        </Button>
      </DialogActions>
      <Toaster
        toastOptions={{
          duration: 1500,
          style: {
            background: "#365b6d",
            color: "#fff",
          },
        }}
      />
    </Dialog>
  );
}

export default ProductModal;
