import { Box } from "@mui/material";
import React from "react";
import Logo from "../images/LOGO.png";
import { useSearchParams } from 'react-router-dom';
import Request from "../Utils/Request/Request";
import { useState } from "react";
import { showToast } from "../Utils";
import { useNavigate } from 'react-router-dom';
import MobLogo from "../images/logoCrop.png";


function ResetPassword() {
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const [confirmPassword, setConfirmPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");

    function ResetPass(e) {
        e.preventDefault()
        Request.post(
          "/resetPassword",
          {
            id: searchParams.get('id'),
            token: searchParams.get('token'),
            newPassword: newPassword,
            confirm_password: confirmPassword
          },
        )
          .then((result) => {
            console.log("Data: ", result.data.message);
            showToast("success", result.data.message);
            setTimeout(() => {
            navigate('/');
            }, 1500);
          })
          .catch((err) => {
            console.log("Error: ", err.response.data);
            if (err.response.status == 401) {
              showToast("error", "Unauthorized user.");
            } else {
              showToast("error", err.response.data);
            }
          });
      }
    
  return (
    <div className="login">
      <div className="MobLogo">
            <img src={MobLogo} alt="" className="MobLogoImg" />
          </div>
      <Box className="container" id="container">
        <div className="form-container log-in-container">
          <form className="form">
            <h4>Enter New Password</h4>

            <input type="password" placeholder="New Password"
              onChange={(event) => setNewPassword(event.target.value)}/>

            <input type="password" placeholder="Confirm New Password"
              onChange={(event) => setConfirmPassword(event.target.value)}/>

            <button type="submit" onClick={ResetPass}>Reset Password</button>
          </form>
        </div>
        <div className="overlay-container">
          <div className="overlay">
            <div className="overlay-panel overlay-right">
              <img className="Login-img" src={Logo} alt="" />
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
}

export default ResetPassword;
