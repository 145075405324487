import React from "react";
import {
  Box,
  Typography,
  Divider,
  FormControl,
  InputLabel,
  Input,
  TextField,
  Grid,
} from "@mui/material";
import { useState, useEffect, useContext } from "react";
import Request from "../Utils/Request/Request";
import { showToast } from "../Utils/index";
import { AppStorage } from "../Utils/Context/AppContext";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";

function UpdateProfile() {
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const AppContext = useContext(AppStorage);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getUserData();
  }, [AppContext?.userInfo]);

  function getUserData() {
    Request.get(
      "/getUserDetails"      
    )
      .then((result) => {
        console.log(result);
        setLoading(false);
        setUserData(result.data["user"]);
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }

  function updateUser() {
    let tempData = userData;
    Request.put("/updateProfile", tempData)
      .then((result) => {
        console.log("Data: ", result.data);
        getUserData();
        showToast("success", result.data.message);
      })
      .catch((err) => {
        console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", err.response.data);
        }
      });
  }
  function updatePassword() {
    // let tempData = userData;
    // tempData['_id'] = userData['_id'];
    Request.put(
      "/updatePassword",
      {
        id: AppContext?.userInfo.id,
        current_password: currentPassword,
        new_password: newPassword,
        confirm_password: confirmPassword,
      }
    )
      .then((result) => {
        console.log("Data: ", result.data.message);
        showToast("success", result.data.message);
      })
      .catch((err) => {
        console.log("Error: ", err.response.data);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", err.response.data);
        }
      });
  }

  return (
    <Box>
      <Typography
        variant="h4"
        sx={{
          paddingBottom: "5px",
        }}
      >
        {t("My Profile")}
      </Typography>
      <Divider variant="middle" />

      <Grid
        sx={{
          paddingTop: "15px",
        }}
        container spacing={5}
      >
        <Grid item sx={{ display: "flex", flexDirection: "column" }}>
          <label>{t("Full Name")}</label>
          <TextField
            value={userData.fullName}
            size="small"
            onChange={(e) =>
              setUserData({ ...userData, fullName: e.target.value })
            }
            sx={{
              "& .MuiInputLabel-shrink": {
                color: "#41c1ba !important",
              },
              "& fieldset": {
                borderColor: "#41c1ba !important",
                "& legend": {
                  "& span": {
                    color: "#41c1ba !important",
                  },
                },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#41c1ba",
              },
              width: "300px",
            }}
          />
        </Grid>
        <Grid item sx={{ display: "flex", flexDirection: "column" }}>
          <label>{t("Username")}</label>
          <TextField
            value={userData.username}
            size="small"
            onChange={(e) =>
              setUserData({ ...userData, username: e.target.value })
            }
            sx={{
              "& .MuiInputLabel-shrink": {
                color: "#41c1ba !important",
              },
              "& fieldset": {
                borderColor: "#41c1ba !important",
                "& legend": {
                  "& span": {
                    color: "#41c1ba !important",
                  },
                },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#41c1ba",
              },
              width: "300px",
            }}
          />
        </Grid>
      </Grid>

      <div>
        <Button
          onClick={updateUser}
          variant="contained"
          size="large"
          sx={{
            marginLeft: "10px",
            marginBottom: "10px",
            marginTop: "15px",
            backgroundColor: "#365b6d",
            "&:hover": { backgroundColor: "#365b6d" },
          }}
        >
          {t("Save")}
        </Button>
      </div>

      <Box>
        <Typography
          variant="h4"
          sx={{
            padding: "11px",
          }}
        >
          {t("Change Password")}
        </Typography>
        {/* <div className="col-md-6" style={{borderBottom:'2px solid black', width:'80px'}}></div> */}
        <div className="col-md-12">
          <Divider variant="middle" sx={{ marginBottom: "15px" }} />
        </div>
      </Box>

      <Grid container spacing={5}>
        <Grid item sx={{ display: "flex", flexDirection: "column" }}>
          <label>{t("Current Password")}</label>
          <TextField
            type={"password"}
            size="small"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            sx={{
              "& .MuiInputLabel-shrink": {
                color: "#41c1ba !important",
              },
              "& fieldset": {
                borderColor: "#41c1ba !important",
                "& legend": {
                  "& span": {
                    color: "#41c1ba !important",
                  },
                },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#41c1ba",
              },
              width: "300px",
            }}
          />
        </Grid>

        <Grid item sx={{ display: "flex", flexDirection: "column" }}>
          <label>{t("New Password")}</label>
          <TextField
            value={newPassword}
            type={"password"}
            size="small"
            onChange={(e) => setNewPassword(e.target.value)}
            sx={{
              "& .MuiInputLabel-shrink": {
                color: "#41c1ba !important",
              },
              "& fieldset": {
                borderColor: "#41c1ba !important",
                "& legend": {
                  "& span": {
                    color: "#41c1ba !important",
                  },
                },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#41c1ba",
              },
              width: "300px",
            }}
          />
        </Grid>
      </Grid>
    <Grid container spacing={5}>
      <Grid item sx={{display:'flex', flexDirection:'column', marginTop:"15px"}}>
          <label>{t("Confirm Password")}</label>
        <TextField
            value={confirmPassword}
            type={"password"}
            size="small"
            onChange={(e) => setConfirmPassword(e.target.value)}
            sx={{
              "& .MuiInputLabel-shrink": {
                color: "#41c1ba !important",
              },
              "& fieldset": {
                borderColor: "#41c1ba !important",
                "& legend": {
                  "& span": {
                    color: "#41c1ba !important",
                  },
                },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#41c1ba",
              },
              width: "300px",
            }}
          />
      </Grid>
      </Grid>
      <div className="col-md-12">
        <Button
          onClick={updatePassword}
          variant="contained"
          size="large"
          sx={{
            marginLeft: "8px",
            marginTop: "15px",
            backgroundColor: "#365b6d",
            "&:hover": { backgroundColor: "#365b6d" },
          }}
        >
          {t("Change Password")}
        </Button>
      </div>
    </Box>
  );
}

export default UpdateProfile;
