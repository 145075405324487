import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Grid, Button, MenuItem, Select, Typography, TextField } from '@mui/material';
import TemplateText from './TemplateText';
import TemplateImage from './TemplateImage';
import TemplateTable from './TemplateTable';
import DeleteIcon from '@mui/icons-material/Delete';
import Request from '../Utils/Request/Request';
import { showToast } from '../Utils';

const TemplateDesign = () => {
  const { id } = useParams();  // Get ID from URL parameters
  const navigate = useNavigate();
  const [templateName, setTemplateName] = useState('');
  const [components, setComponents] = useState([]);
  const [templateId, setTemplateId] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (id) {
      const fetchTemplate = async () => {
        try {
          const response = await Request.get(`/getTemplateById/${id}`);
          console.log(response);
  
          const sortedComponents = response.data.template_values
            .map(component => {
              let parsedValue = component.value;
  
              // Attempt to parse value if it's a string
              if (typeof component.value === 'string') {
                try {
                  parsedValue = JSON.parse(component.value);
                } catch (error) {
                  // If parsing fails, keep the original string value
                  parsedValue = component.value;
                }
              }
  
              return {
                ...component,
                value: parsedValue,
              };
            })
            .sort((a, b) => a.indexNo - b.indexNo);
  
          setTemplateName(response.data.name);
          setComponents(sortedComponents);
          setTemplateId(response.data.id);
        } catch (error) {
          console.error('Error fetching template: ', error);
        }
      };
  
      fetchTemplate();
    }
  }, [id]);
  
  

  const addComponent = (type) => {
    setComponents([...components, { type, value: [], size: 12, tableType: "", indexNo: components.length, align: "right", fontWeight: "normal", fontWeightValue:"normal" }]);
  };

  const updateComponent = (index, value) => {
    const newComponents = [...components];
    newComponents[index].value = value;
    setComponents(newComponents);
  };

  const updateComponentSize = (index, size) => {
    const newComponents = [...components];
    newComponents[index].size = size;
    setComponents(newComponents);
  };

  const updateComponentData = (index, data) => {
    const newComponents = [...components];
    newComponents[index] = { ...newComponents[index], ...data };
    setComponents(newComponents);
  };

  const removeComponent = (index) => {
    const newComponents = components.filter((_, i) => i !== index);
    setComponents(newComponents);
  };

  const validateComponents = () => {
    for (const component of components) {
      if (component.value === "" || component.value.length === 0) {
        return false;
      }
    }
    return true;
  };

  const saveTemplate = async () => {
    if (!templateName.trim()) {
      showToast("error", "Template name cannot be empty.");
      return;
    }

    if (!validateComponents()) {
      showToast("error", "All components must have type, value, and size filled out.");
      return;
    }
    if (components.length < 1) {
      showToast("error", "At least one component is required.");
      return;
    }
    setIsSubmitting(true);
    const formattedComponents = components.map(component => ({
      indexNo: component.indexNo,
      type: component.type,
      // value: component.value,
      value: component.value, // Only save the value
      size: component.size,
      tableType: component.tableType,
      align: component.align,
      fontWeight: component.fontWeight,
      fontWeightValue: component.fontWeightValue
    }));

    const endpoint = templateId ? `/updateTemplate` : `/saveTemplate`;
    const payload = templateId ? { id: templateId, name: templateName, components: formattedComponents } : { name: templateName, components: formattedComponents };

    try {
      await Request.post(endpoint, payload);
      setIsSubmitting(false);
      showToast("success", "Template Saved.");
      navigate('/templateList');
    } catch (err) {
      setIsSubmitting(false);
      console.log("Error: ", err);
      if (err.response.status === 401) {
        showToast("error", "Unauthorized user.");
      } else if (err.response.status === 409) {
        showToast("error", err.response.data.error);
      } else {
        showToast("error", "Something went wrong.");
      }
    }
  };
  const changeComponentType = (index, newType) => {
    setComponents((prevComponents) => {
      const updatedComponents = [...prevComponents];
      const currentComponent = updatedComponents[index];
      
      if (currentComponent.type !== newType) {
        currentComponent.type = newType;
        currentComponent.value = []; // Reset value when type changes
      }

      return updatedComponents;
    });
  };
  
  
  
  return (
    <Box sx={{ px: 5 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
        <TextField
          label="Template Name"
          value={templateName}
          onChange={(e) => setTemplateName(e.target.value)}
          sx={{ flexGrow: 1, mr: 2 }}
        />
        <Button
          onClick={saveTemplate}
          variant="contained"
          color="primary"
          disabled={isSubmitting}
        >
          Save Template
        </Button>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", gap: 4 }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => addComponent("text")}
          >
            Add Text
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => addComponent("image")}
          >
            Add Image
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => addComponent("table")}
          >
            Add Table
          </Button>
        </Box>
      </Box>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {components.map((component, index) => (
          <Grid item xs={component.size} key={index}>
            <Box mb={2}>
                <Typography variant="body2" mr={1}>
                  Component Type:
                </Typography>
                <Select
                  value={component.type}
                  onChange={(e) => changeComponentType(index, e.target.value)}
                >
                  <MenuItem value="text">Text</MenuItem>
                  <MenuItem value="image">Image</MenuItem>
                  <MenuItem value="table">Table</MenuItem>
                </Select>
              </Box>
            <Box
              sx={{
                border: "1px solid #ccc",
                padding: "10px",
                borderRadius: "4px",
                position: "relative",
                background: "#fff",
              }}
            >
              {component.type === "text" && (
                <TemplateText
                  value={component.value}
                  onChange={(value) => updateComponent(index, value)}
                />
              )}
              {component.type === "image" && (
                <TemplateImage
                  value={component.value}
                  onChange={(value) => updateComponent(index, value)}
                />
              )}
              {component.type === "table" && (
                <TemplateTable
                  data={component}
                  onChange={(data) => updateComponentData(index, data)}
                  tableType={component.tableType}
                />
              )}
              <Box sx={{ width:"100%", display: "flex", justifyContent:"space-evenly", flexWrap: "wrap", mt:2 }}>
                <Box>
                  <Typography variant="body2" mr={1}>
                    Size:
                  </Typography>
                  <Select
                    value={component.size}
                    onChange={(e) => updateComponentSize(index, e.target.value)}
                  >
                    {[...Array(12)].map((_, i) => (
                      <MenuItem key={i} value={i + 1}>
                        {i + 1}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box>
                  <Typography variant="body2" mr={1}>
                    Align:
                  </Typography>
                  <Select
                    value={component.align}
                    onChange={(e) =>
                      updateComponentData(index, { align: e.target.value })
                    }
                  >
                    <MenuItem value="right">Right</MenuItem>
                    <MenuItem value="center">Center</MenuItem>
                    <MenuItem value="left">Left</MenuItem>
                  </Select>
                </Box>
                <Box>
                  <Typography variant="body2" mr={1}>
                    Font Title:
                  </Typography>
                  <Select
                    value={component.fontWeight}
                    onChange={(e) =>
                      updateComponentData(index, { fontWeight: e.target.value })
                    }
                  >
                    <MenuItem value="bold">Bold</MenuItem>
                    <MenuItem value="normal">Normal</MenuItem>
                  </Select>
                </Box>
                <Box>
                  <Typography variant="body2">
                    Font Value:
                  </Typography>
                  <Select
                    value={component.fontWeightValue}
                    onChange={(e) =>
                      updateComponentData(index, { fontWeightValue: e.target.value })
                    }
                  >
                    <MenuItem value="bold">Bold</MenuItem>
                    <MenuItem value="normal">Normal</MenuItem>
                  </Select>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" mt={1}>
                <Button
                  onClick={() => removeComponent(index)}
                  sx={{ color: "red" }}
                >
                  <DeleteIcon />
                </Button>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default TemplateDesign;
