import i18n from "i18next";
import { initReactI18next } from "react-i18next";


// Importing translation files

import translationEN from "./locales/en.json";
import translationAR from "./locales/ar.json";


//Creating object with the variables of imported translation files
const resources = {
  en: {
    translation: translationEN,
  },
  ar: {
    translation: translationAR,
  },
};
const storedLanguage = localStorage.getItem("selectedLanguage") || "en";
//i18N Initialization

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng:storedLanguage, //default language
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;