import React from "react";
import TableProduct from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

function DialogProducts({
  openInvoiceProducts,
  text,
  handleCloseInvoiceProducts,
}) {
  return (
    <div>
      <Dialog
        open={openInvoiceProducts}
        onClose={() => handleCloseInvoiceProducts()}
      >
        <DialogTitle sx={{ fontSize: "16px", fontWeight: "bold" }}>
          Items Sold
        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <TableProduct
              sx={{ minWidth: 350 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead
                sx={{
                  borderBottom: "3px solid #d2d2d2",
                  background: "#f9f9fc",
                }}
              >
                <TableRow>
                  <TableCell
                    align="left"
                    sx={{
                      fontSize: "16px",
                      borderRight: "1px solid #d2d2d2",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      fontSize: "16px",
                      borderRight: "1px solid #d2d2d2",
                    }}
                  >
                    Quantity
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: "16px" }}>
                    Price
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {text?.value?.map((invoice) => (
                  <TableRow
                    key={invoice.productName}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="left" sx={{ fontSize: "14px" }}>
                      {invoice.productName}
                    </TableCell>
                    <TableCell align="left" sx={{ fontSize: "14px" }}>
                      {invoice.qty}
                    </TableCell>
                    <TableCell align="left" sx={{ fontSize: "14px" }}>
                      {invoice.unitPrice}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TableProduct>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseInvoiceProducts()}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DialogProducts;
