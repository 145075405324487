import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Divider } from "@mui/material";

function CreateInvoiceModal({ confirm, openReturnConfirm, setOpenReturnModal, id, setId }) {    
  return (
    <Box>
      <Dialog
        open={openReturnConfirm}
        //   onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{fontSize:"16px"}}>Are you sure you want to create invoice?</DialogTitle>
        <Divider />
        <DialogActions>
          <Button onClick={() => setOpenReturnModal(false)}>Cancel</Button>
          <Button onClick={() => confirm(id) }>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default CreateInvoiceModal;
