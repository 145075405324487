import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Request from "../Utils/Request/Request";
import { useState, useContext, useEffect } from "react";
import { AppStorage } from "../Utils/Context/AppContext";
import { showToast } from "../Utils/index";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import UserModal from "../modals/UserModal";
import Button from "@mui/material/Button";
import DeleteModal from "./DeleteModal";
import { TextField, Typography } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Loader from "./Loader";
import SubAdminModal from "../modals/SubAdminModal";

function AllSubAdmin() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openDelModal, setOpenDelModal] = useState(false);

  const OpenDelModal = (user) => {
    setOpenDelModal(true);
    setDelSubAdmin(user);
  };

  const CloseDelModal = () => {
    setOpenDelModal(false);
  };

  const [delSubAdmin, setDelSubAdmin] = useState("");
  const [subAdmin, setSubAdmin] = useState([]);
  const [subAdminToUpdate, setSubAdminToUpdate] = useState(null);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 7,
  });
  const handleChange = (event, value) => {
    let dupPagination = { ...pagination };
    dupPagination.current = value;
    setPagination(dupPagination);
  };
  const [sort, setSort] = useState({ sortBy: "id", sortOrder: "ASC" });
  const [searchPhrase, setSearchPhrase] = useState("");

  const AppContext = useContext(AppStorage);

  useEffect(() => {
    if(AppContext?.userAuthToken || AppContext?.userAuthToken !== ""){
    getData({ pagination, sort, searchPhrase });
    }
  }, [AppContext?.userAuthToken, pagination?.current]);

  useEffect(() => {
    if (forceUpdate) {
      getData({ pagination, sort, searchPhrase });
    }
  }, [forceUpdate]);

  function getData(params) {
    setLoading(true);
    let paramsData = {};

    paramsData = {
      ...paramsData,
      ...params.sort,
      searchPhrase: params.searchPhrase,
    };

    Request.get(`/getAllSubAdmins/${params.pagination?.current}`, paramsData)
      .then((result) => {
        setLoading(false);
        setSubAdmin(result.data["data"]);
        console.log(result.data["data"]);
        setForceUpdate(false);
        setPagination({
          ...params.pagination,
          total: result.data["totalCount"],
        });
        setSearchPhrase(params.searchPhrase);
        setSort({
          ...params.sort,
        });
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }

  function searchChange(value) {
    getData({ pagination, searchPhrase: value });
  }
  // Function to recalculate row numbers
  const updateRowNumbers = () => {
    const subAdminWithRowNumber = subAdmin.map((subAdmin, index) => {
      return { ...subAdmin, rowNumber: (pagination.current - 1) * 7 + (index + 1) };
    });
    setSubAdmin(subAdminWithRowNumber);
  };

  // Function to handle row deletion
  const handleRowDelete = (selectedRowIds) => {
    const updatedUsers = subAdmin.filter(
      (user) => !selectedRowIds.includes(user.id)
    );
    setSubAdmin(updatedUsers);
    updateRowNumbers(); // Recalculate row numbers after deletion
  };
  
  const columns = [
    { field: "rowNumber", headerName: "Sr-No.", width: 80 },
    { field: "fullName", headerName: "Full Name", width: 150 },
    { field: "email", headerName: "Email", width: 220 },
    { field: "username", headerName: "User Name", width: 120 },
    { field: "canEdit", headerName: "Edit Invoice", width: 150,
    renderCell: (params) => {
      return params.value ? (
        <div style={{width:'100%', textAlign:"center"}}>
       <p style={{fontSize:'18px', color:'green'}}>✔</p>
       </div>
      ) : (
        <div style={{width:'100%', textAlign:"center"}}>
       <p>❌</p>
       </div>
      );
    },
  },
  { field: "canDelete", headerName: "Delete Invoice", width: 150,
    renderCell: (params) => {
      return params.value ? (
        <div style={{width:'100%', textAlign:"center"}}>
       <p style={{fontSize:'18px', color:'green'}}>✔</p>
       </div>
      ) : (
        <div style={{width:'100%', textAlign:"center"}}>
       <p>❌</p>
       </div>
      );
    },
  },
    {
      headerName: "Actions",
      renderCell: (record) => {
        return (
          <Box sx={{ display: "flex", gap: "8px" }}>
            <EditIcon
              onClick={() => UpdateSubAdminModal(record.row)}
              sx={{ cursor: "pointer", color: "#41c1ba" }}
            />
            <DeleteIcon
              sx={{ cursor: "pointer", color: "#365b6d" }}
              onClick={() => OpenDelModal(record.row)}
            />
          </Box>
        );
      },
    },
  ];

  function NewSubAdminModal() {
    setOpen(true);
    setSubAdminToUpdate(null);
  }

  function UpdateSubAdminModal(user) {
    setOpen(true);
    setSubAdminToUpdate(user);
  }
  
  function deleteUser(user) {
    Request.delete(`/delUser/${user["id"]}`)
      .then(() => {
        setForceUpdate(true);
        CloseDelModal();
        showToast("success", "Deleted Successfully.");
      })
      .catch((err) => {
        console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          marginBottom: "8px",
        }}
      >
        <TextField
          type="text"
          placeholder="Search By Name"
          onChange={(event) => searchChange(event.target.value)}
          sx={{
            width: "250px",
            marginRight: "20px",
            "& .MuiOutlinedInput-input": { height: "0.5em" },
            "& fieldset": {
              borderColor: "#365b6d !important",
            },
          }}
        />

        <Button
         className="largeScreen-btn"
          onClick={NewSubAdminModal}
          variant="contained"
          sx={{
            background: "#365b6d",
            "&:hover": { backgroundColor: "#365b6d" },
          }}
        >
          Add New Sub Admin
        </Button>

        <Button
        className="smallScreen-btn"
          onClick={NewSubAdminModal}
          variant="contained"
          sx={{
            background: "#365b6d",
            "&:hover": { backgroundColor: "#365b6d" },
            fontSize:"30px",
            height: "40px",
          }}
        >
          +
        </Button>
      </Box>

      
        <div style={{ height: "calc(100vh - 235px)", width: "100%" }}>
          <Typography
            variant="h6"
            sx={{ color: "#365b6d", textAlign: "center" }}
          >
            Sub Admin
          </Typography>
          {loading ? (
        <Loader />
      ) : (
        <>
          <DataGrid
            rows={subAdmin.map((user, index) => ({ ...user, rowNumber: (pagination.current - 1) * 7 + (index + 1) }))}
            columns={columns}
            hideFooter
            disableColumnMenu
            checkboxSelection={false}
            disableSelectionOnClick
            sx={{background:"white"}}
            onSelectionModelChange={(newSelection) => handleRowDelete(newSelection)}
          />
          <Stack spacing={2} marginTop="5px">
            <Pagination
              count={Math.ceil(pagination?.total / 7)}
              page={pagination?.current}
              onChange={handleChange}
            />
          </Stack>
          </>
      )}
        </div>
      {open && (
        <SubAdminModal
          close={handleClose}
          open={open}
          handleClickOpen={handleClickOpen}
          user={subAdminToUpdate}
          setForceUpdate={setForceUpdate}
          setUserToUpdate={setSubAdminToUpdate}
        />
      )}
      {openDelModal && (
        <DeleteModal
          del={deleteUser}
          currentUser={delSubAdmin}
          openDelModal={openDelModal}
          setDeleteModal={setOpenDelModal}
        />
      )}
    </Box>
  );
}

export default AllSubAdmin;
