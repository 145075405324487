import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Request from "../Utils/Request/Request";
import { useState, useContext, useEffect } from "react";
import { AppStorage } from "../Utils/Context/AppContext";
import { showToast } from "../Utils/index";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import UserModal from "../modals/UserModal";
import Button from "@mui/material/Button";
import DeleteModal from "./DeleteModal";
import { Switch, TextField, Typography } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Loader from "./Loader";
import { id } from "date-fns/locale";

function AllUsers() {
  const [open, setOpen] = React.useState(false);
  const [checkedStates, setCheckedStates] = useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openDelModal, setOpenDelModal] = useState(false);

  const OpenDelModal = (user) => {
    setOpenDelModal(true);
    setDelUser(user);
  };

  const CloseDelModal = () => {
    setOpenDelModal(false);
  };

  const [delUser, setDelUser] = useState("");
  const [users, setUsers] = useState([]);
  const [userToUpdate, setUserToUpdate] = useState(null);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 7,
  });
  const handleChange = (event, value) => {
    let dupPagination = { ...pagination };
    dupPagination.current = value;
    setPagination(dupPagination);
  };
  const [sort, setSort] = useState({ sortBy: "id", sortOrder: "ASC" });
  const [searchPhrase, setSearchPhrase] = useState("");

  const AppContext = useContext(AppStorage);

  useEffect(() => {
    if (AppContext?.userAuthToken || AppContext?.userAuthToken !== "") {
      getData({ pagination, sort, searchPhrase });
    }
  }, [AppContext?.userAuthToken, pagination?.current]);

  useEffect(() => {
    if (forceUpdate) {
      getData({ pagination, sort, searchPhrase });
    }
  }, [forceUpdate]);

  function getData(params) {
    setLoading(true);
    let paramsData = {};

    paramsData = {
      ...paramsData,
      ...params.sort,
      searchPhrase: params.searchPhrase,
    };

    Request.get(`/getAllUsers/${params.pagination?.current}`, paramsData)
      .then((result) => {
        setLoading(false);
        setUsers(result.data["data"]);
        let obj = {};
        result.data.data.forEach((item) => {
          obj[item.id] = item.dateEdit;
        });
        setCheckedStates(obj);
        console.log(result.data["data"]);
        setForceUpdate(false);
        setPagination({
          ...params.pagination,
          total: result.data["totalCount"],
        });
        setSearchPhrase(params.searchPhrase);
        setSort({
          ...params.sort,
        });
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }

  function searchChange(value) {
    getData({ pagination, searchPhrase: value });
  }
  // Function to recalculate row numbers
  const updateRowNumbers = () => {
    const usersWithRowNumber = users.map((user, index) => {
      return { ...user, rowNumber: (pagination.current - 1) * 7 + (index + 1) };
    });
    setUsers(usersWithRowNumber);
  };

  // Function to handle row deletion
  const handleRowDelete = (selectedRowIds) => {
    const updatedUsers = users.filter(
      (user) => !selectedRowIds.includes(user.id)
    );
    setUsers(updatedUsers);
    updateRowNumbers(); // Recalculate row numbers after deletion
  };
  const columns = [
    // { field: "id", headerName: "ID", width: 50 },
    { field: "rowNumber", headerName: "Sr-No.", width: 70 },
    { field: "fullName", headerName: "Full Name", width: 150 },
    { field: "email", headerName: "Email", width: 150 },
    { field: "username", headerName: "User Name", width: 120 },
    { field: "invoiceType", headerName: "Invoice Type", width: 120 },
    { field: "companyName", headerName: "Company Name", width: 150 },
    { field: "companyNumber", headerName: "Company Number", width: 150 },
    { field: "vatNumber", headerName: "Vat Number", width: 150 },
    { field: "address", headerName: "Address", width: 150 },
    {
      field: "dateEdit",
      headerName: "Edit Date",
      width: 70,
      renderCell: (params) => {
        const id = params.row.id;
        return (
          <div style={{ width: "100%", textAlign: "center" }}>
            <Switch
              checked={checkedStates[id] || false}
              disabled = {AppContext?.userInfo.role === "sub-admin" ? true : false}
              onChange={(e) => toggleSwitch(id, e.target.checked)}
            />
          </div>
        );
      },
    },
    { field: "role", headerName: "Role", width: 70 },
    { field: "phoneNumber", headerName: "Phone", width: 100 },
    {
      field: "isActive",
      headerName: "Status",
      width: 100,
      renderCell: (params) => {
        return params.value ? (
          <div style={{ width: "100%", textAlign: "center" }}>
            <p style={{ fontSize: "18px", color: "green" }}>✔</p>
          </div>
        ) : (
          <div style={{ width: "100%", textAlign: "center" }}>
            <p>❌</p>
          </div>
        );
      },
    },
    {
      field: "logo",
      headerName: "Logo",
      width: 150,
      renderCell: (params) => {
        return params.value ? (
          <img
            src={`https://api.doryalinvoices.com/image-storage/${params.value}`}
            style={{ height: "40px", width: "40px", borderRadius: "24px" }}
            alt="N/A"
          />
        ) : (
          <div>N/A</div>
        );
      },
    },
    {
      headerName: "Actions",
      renderCell: (record) => {
        
        return (
          <Box sx={{ display: "flex", gap: "8px" }}>
            <EditIcon
              onClick={() => UpdateUserModal(record.row)}
              sx={{ cursor: "pointer", color: "#41c1ba" }}
            />
            <DeleteIcon
              sx={{ cursor: "pointer", color: "#365b6d" }}
              onClick={() => OpenDelModal(record.row)}
            />
          </Box>
        );
      },
    },
  ];

  function NewUserModal() {
    setOpen(true);
    setUserToUpdate(null);
  }

  function UpdateUserModal(user) {
    setOpen(true);
    setUserToUpdate(user);
  }

  function deleteUser(user) {
    Request.delete(`/delUser/${user["id"]}`)
      .then(() => {
        setForceUpdate(true);
        CloseDelModal();
        showToast("success", "Deleted Successfully.");
      })
      .catch((err) => {
        console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }

  const EditDate = (id, value) => {
    let tempData = { dateEdit: value };
    Request.put(`/updateEditToggles/${id}`, tempData)
      .then((result) => {
        showToast("success", "Edit Date Updated");
        console.log("Data: ", result.data);
        getData({ pagination, sort, searchPhrase });
      })
      .catch((err) => {
        console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", err?.response?.data?.error);
        }
      });
  };
  const toggleSwitch = (id, value) => {
    setCheckedStates((prevCheckedStates) => ({
      ...prevCheckedStates,
      [id]: value,
    }));
    EditDate(id, value);
  };
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          marginBottom: "8px",
        }}
      >
        <TextField
          type="text"
          placeholder="Search By Name"
          onChange={(event) => searchChange(event.target.value)}
          sx={{
            width: "250px",
            marginRight: "20px",
            "& .MuiOutlinedInput-input": { height: "0.5em" },
            "& fieldset": {
              borderColor: "#365b6d !important",
            },
          }}
        />

        <Button
          className="largeScreen-btn"
          onClick={NewUserModal}
          variant="contained"
          sx={{
            background: "#365b6d",
            "&:hover": { backgroundColor: "#365b6d" },
          }}
        >
          Add New User
        </Button>

        <Button
          className="smallScreen-btn"
          onClick={NewUserModal}
          variant="contained"
          sx={{
            background: "#365b6d",
            "&:hover": { backgroundColor: "#365b6d" },
            fontSize: "30px",
            height: "40px",
          }}
        >
          +
        </Button>
      </Box>

      <div style={{ height: "calc(100vh - 235px)", width: "100%" }}>
        <Typography variant="h6" sx={{ color: "#365b6d", textAlign: "center" }}>
          All Users
        </Typography>
        {loading ? (
          <Loader />
        ) : (
          <>
            <DataGrid
              rows={users.map((user, index) => ({
                ...user,
                rowNumber: (pagination.current - 1) * 7 + (index + 1),
              }))}
              columns={columns}
              hideFooter
              disableColumnMenu
              checkboxSelection={false}
              disableSelectionOnClick
              sx={{ background: "white" }}
              onSelectionModelChange={(newSelection) =>
                handleRowDelete(newSelection)
              }
            />
            <Stack spacing={2} marginTop="5px">
              <Pagination
                count={Math.ceil(pagination?.total / 7)}
                page={pagination?.current}
                onChange={handleChange}
              />
            </Stack>
          </>
        )}
      </div>
      {open && (
        <UserModal
          close={handleClose}
          open={open}
          handleClickOpen={handleClickOpen}
          user={userToUpdate}
          setForceUpdate={setForceUpdate}
          setUserToUpdate={setUserToUpdate}
        />
      )}
      {openDelModal && (
        <DeleteModal
          del={deleteUser}
          currentUser={delUser}
          openDelModal={openDelModal}
          setDeleteModal={setOpenDelModal}
        />
      )}
    </Box>
  );
}

export default AllUsers;
