import React, { useState, useContext, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Grid } from "@mui/material";
import Request from "../Utils/Request/Request";
import { showToast } from "../Utils/index";
import { AppStorage } from "../Utils/Context/AppContext";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";

function ClientModal({ close, open, client, setForceUpdate }) {
  const initialState = {
    email: "",
    companyName: "",
    companyNumber: null,
    vatNumber: null,
    address: "",
    phoneNumber: null,
  };
  const [formData, setFormData] = useState(initialState);
  const [validated, setValidated] = useState(true);
  const { t, i18n } = useTranslation();

  const AppContext = useContext(AppStorage);

  useEffect(() => {
    if (client) {
      let dupObj = { ...formData };
      dupObj.email = client["email"];
      dupObj.vatNumber = client["vatNumber"];
      dupObj.companyName = client["companyName"];
      dupObj.companyNumber = client["companyNumber"];
      dupObj.phoneNumber = client["phoneNumber"];
      dupObj.address = client["address"];
      setFormData(dupObj);
    }
  }, []);

  const validateFormData = () => {
    if (
      formData.companyName === "" ||
      formData.vatNumber === null
      // formData.companyNumber === null ||
      // formData.phoneNumber === null ||
      // formData.address === ""
    ) {
      setValidated(false);
      return false;
    }
    //  else if (
    //   !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)
    // )
    //  {
    //   setValidated(false);
    //   return false;
    // }
    setValidated(true);
    return true;
  };

  const handleSubmit = () => {
    if (validateFormData()) {
      let tempData = {
        email: formData.email,
        companyName: formData.companyName,
        companyNumber: formData.companyNumber,
        vatNumber: formData.vatNumber,
        address: formData.address,
        phoneNumber: formData.phoneNumber,
      };
      if (client == null) {
        Request.post("/createClient", tempData)
          .then((result) => {
            toast.success("New Client Added");
            setTimeout(() => {
              close();
              setForceUpdate(true);
            }, 1500);
            console.log("Data: ", result.data);
          })
          .catch((err) => {
            console.log("Error: ", err);
            if (err.response.status == 401) {
              showToast("error", "Unauthorized user.");
            } else {
              showToast("error", "Something went wrong.");
            }
          });
      } else {
        tempData["id"] = client["id"];
        Request.put("/updateClient", tempData)
          .then((result) => {
            console.log("Data: ", result.data);
            toast.success("Client Updated");
            setTimeout(() => {
              close();
              setForceUpdate(true);
            }, 1500);
          })
          .catch((err) => {
            console.log("Error: ", err);
            if (err.response.status == 401) {
              showToast("error", "Unauthorized user.");
            } else {
              showToast("error", "Something went wrong.");
            }
          });
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px", // Set your width here
          },
        },
      }}
    >
      <DialogTitle>
        {client === null ? `${t("Add New Client")}` : `${t("Update Client")}`}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{marginTop: "8px"}}>
          {/* <Grid item>
            <TextField
              label={t("Email")}
              size="small"
              value={formData.email}
              onChange={(e) => {
                let dupObj = { ...formData };
                dupObj.email = e.target.value;
                setFormData(dupObj);
              }}
              sx={{
                "& .MuiInputLabel-shrink": {
                  color: "#41c1ba !important",
                },
                "& fieldset": {
                  borderColor: "#41c1ba !important",
                  "& legend": {
                    "& span": {
                      color: "#41c1ba !important",
                    },
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#41c1ba",
                },
                width: "200px",
              }}
            />
            {!validated &&
              !formData.email.match(
                /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
              ) && (
                <div style={{ color: "red", fontSize: "small" }}>
                  InValid Email
                </div>
              )}
          </Grid> */}
          <Grid item>
            <TextField
              label={t("Company Name")}
              size="small"
              value={formData.companyName}
              onChange={(e) => {
                let dupObj = { ...formData };
                dupObj.companyName = e.target.value;
                setFormData(dupObj);
              }}
              sx={{
                "& .MuiInputLabel-shrink": {
                  color: "#41c1ba !important",
                },
                "& fieldset": {
                  borderColor: "#41c1ba !important",
                  "& legend": {
                    "& span": {
                      color: "#41c1ba !important",
                    },
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#41c1ba",
                },
                width: "200px",
              }}
            />
            {!validated &&
              (formData.companyName === null ||
                formData.companyName === "") && (
                <div style={{ color: "red", fontSize: "small" }}>
                  Company Name is required
                </div>
              )}
          </Grid>
          {/* </Box> */}

          <Grid item>
            <TextField
              type={"number"}
              size="small"
              label={t("Company Number")}
              value={formData.companyNumber}
              onChange={(e) => {
                let dupObj = { ...formData };
                dupObj.companyNumber = e.target.value;
                setFormData(dupObj);
              }}
              sx={{
                "& .MuiInputLabel-shrink": {
                  color: "#41c1ba !important",
                },
                "& fieldset": {
                  borderColor: "#41c1ba !important",
                  "& legend": {
                    "& span": {
                      color: "#41c1ba !important",
                    },
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#41c1ba",
                },
                width: "200px",
              }}
            />
            {/* {!validated &&
              (formData.companyNumber === null ||
                formData.companyNumber === "") && (
                <div style={{ color: "red", fontSize: "small" }}>
                  Company Number is required
                </div>
              )} */}
          </Grid>
          <Grid item>
            <TextField
              type={"number"}
              size="small"
              label={t("Vat Number")}
              value={formData.vatNumber}
              onChange={(e) => {
                let dupObj = { ...formData };
                dupObj.vatNumber = e.target.value;
                setFormData(dupObj);
              }}
              sx={{
                "& .MuiInputLabel-shrink": {
                  color: "#41c1ba !important",
                },
                "& fieldset": {
                  borderColor: "#41c1ba !important",
                  "& legend": {
                    "& span": {
                      color: "#41c1ba !important",
                    },
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#41c1ba",
                },
                width: "200px",
              }}
            />
            {!validated &&
              (formData.vatNumber === null || formData.vatNumber === "") && (
                <div style={{ color: "red", fontSize: "small" }}>
                  Vat Number is required
                </div>
              )}
          </Grid>

          <Grid item>
            <TextField
              type={"number"}
              size="small"
              label={t("Phone Number")}
              value={formData.phoneNumber}
              onChange={(e) => {
                let dupObj = { ...formData };
                dupObj.phoneNumber = e.target.value;
                setFormData(dupObj);
              }}
              sx={{
                "& .MuiInputLabel-shrink": {
                  color: "#41c1ba !important",
                },
                "& fieldset": {
                  borderColor: "#41c1ba !important",
                  "& legend": {
                    "& span": {
                      color: "#41c1ba !important",
                    },
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#41c1ba",
                },
                width: "200px",
              }}
            />
            {/* {!validated &&
              (formData.phoneNumber === null ||
                formData.phoneNumber === "") && (
                <div style={{ color: "red", fontSize: "small" }}>
                  Phone Number is required
                </div>
              )} */}
          </Grid>

          <Grid item>
            <TextField
              label={t("Address")}
              size="small"
              value={formData.address}
              onChange={(e) => {
                let dupObj = { ...formData };
                dupObj.address = e.target.value;
                setFormData(dupObj);
              }}
              sx={{
                "& .MuiInputLabel-shrink": {
                  color: "#41c1ba !important",
                },
                "& fieldset": {
                  borderColor: "#41c1ba !important",
                  "& legend": {
                    "& span": {
                      color: "#41c1ba !important",
                    },
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#41c1ba",
                },
                width: "200px",
              }}
            />
            {/* {!validated &&
              (formData.address === null || formData.address === "") && (
                <div style={{ color: "red", fontSize: "small" }}>
                  address is required
                </div>
              )} */}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>{t("Cancel")}</Button>
        <Button
          variant="contained"
          sx={{
            background: "#365b6d",
            "&:hover": { backgroundColor: "#365b6d" }
           
          }}
          onClick={handleSubmit}
        >
          {client === null ? `${t("Add Client")}` : `${t("Update Client")}`}
        </Button>
      </DialogActions>
      <Toaster
        toastOptions={{
          duration: 1500,
          style: {
            background: "#365b6d",
            color: "#fff",
          },
        }}
      />
    </Dialog>
  );
}

export default ClientModal;
