import React, { useState, useContext, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Grid, InputLabel } from "@mui/material";
import Request from "../Utils/Request/Request";
import { showToast } from "../Utils/index";
import toast, { Toaster } from "react-hot-toast";
import Checkbox from "@mui/material/Checkbox";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";


function SubAdminModal({ close, open, user, setForceUpdate }) {
  const initialState = {
    fullName: "",
    email: "",
    password: "",
    username: "",
    canEdit: true,
    canDelete: true,
    showInvoice: null,
  };

  const [canEdit, setCanEdit] = useState(true);
  const [canDelete, setCanDelete] = useState(true);
  const [formData, setFormData] = useState(initialState);
  const [validated, setValidated] = useState(true);

  const handleEditChange = (event) => {
    let dupObj = { ...formData };
    dupObj.canEdit = event.target.checked;
    setCanEdit(event.target.checked);
    setFormData(dupObj);
  };

  const handleDelChange = (event) => {
    let dupObj = { ...formData };
    dupObj.canDelete = event.target.checked;
    setCanDelete(event.target.checked);
    setFormData(dupObj);
  };
  
  useEffect(() => {
    if (user) {
      let dupObj = { ...formData };
      dupObj.fullName = user["fullName"];
      dupObj.email = user["email"];
      dupObj.username = user["username"];
      dupObj.password = user["password"];     
      dupObj.canEdit = user["canEdit"];
      dupObj.showInvoice = dayjs(user["showInvoice"]);
      dupObj.canDelete = user["canDelete"];
      setFormData(dupObj);
      setCanEdit(user["canEdit"])
      setCanDelete(user["canDelete"])
    }
  }, []);



  const validateFormData = () => {
    if (
      formData.fullName === "" ||
      formData.password === "" ||
      formData.showInvoice === "" ||
      formData.showInvoice === null ||
      formData.username === "" ||
      formData.email === "" ||
      !formData.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    ) {
      setValidated(false);
      return false;
    }
    setValidated(true);
    return true;
  };
 
  const handleSubmit = () => {
    if (validateFormData()) {
      let tempData = new FormData();
      tempData.append("fullName", formData.fullName);
      tempData.append("email", formData.email);
      tempData.append("password", formData.password);
      tempData.append("username", formData.username);
      tempData.append("canEdit", formData.canEdit);
      let showInvoiceDate;
      if (formData.showInvoice) {
        showInvoiceDate = new Date(formData.showInvoice);
      } else {
        showInvoiceDate = new Date('2099-12-12');
      }
      showInvoiceDate.setUTCHours(0, 0, 0, 0);
      tempData.append("showInvoice", showInvoiceDate);
      tempData.append("canDelete", formData.canDelete);


      if (user == null) {
        Request.post("/createSubAdmin", tempData, {
          "Content-Type": "multipart/form-data",
        })
          .then((result) => {
            toast.success("New Sub-Admin Added");
            setTimeout(() => {
              setFormData(initialState);
              close();
              setForceUpdate(true);
            }, 1500);
            console.log("Data: ", result.data);
          })
          .catch((err) => {
            console.log("Error: ", err);
            if (err.response.status == 401) {
              showToast("error", "Unauthorized user.");
            } else {
              showToast("error", "Something went wrong.");
            }
          });
      } else {
        tempData.append("id", user["id"]);
        Request.put("/updateSubAdmin", tempData, {        
          "Content-Type": "multipart/form-data",
        })
          .then((result) => {
            console.log("Data: ", result.data);
            toast.success("Sub Admin Updated");
            setTimeout(() => {
              setFormData(initialState);
              close();
              setForceUpdate(true);
            }, 1500);
          })
          .catch((err) => {
            console.log("Error: ", err);
            if (err.response.status == 401) {
              showToast("error", "Unauthorized user.");
            } else {
              showToast("error", "Something went wrong.");
            }
          });
      }
    }
  };

  useEffect(() => {
    if(user === null){
    generatePassword()
  }
  }, [open, user]);
  
  const generatePassword = () => {
    let pass =  '';
    let string = "ABCDEFGHIJKLMNOPQRSTUVWXUZ" + "abcdefghijklmnopqrstuvwxyz123456789@#$";
    for (let i=1; i <= 16; i++){
      let char = Math.floor(Math.random() * string.length + 1);
      
      pass += string.charAt(char)
    }
    let dupObj = {...formData}
    dupObj.password = pass
    setFormData(dupObj)
  }
  return (
    <Dialog
      open={open}
      onClose={close}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px", // Set your width here
          },
        },
      }}
    >
      <DialogTitle>
        {user === null ? "Add Sub-Admin" : "Update Sub-Admin"}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} style={{ marginTop: "10px" }}>
          <Grid item xs="auto">
            <TextField
              label="Full Name"
              size="small"
              value={formData.fullName}
              onChange={(e) => {
                let dupObj = { ...formData };
                dupObj.fullName = e.target.value;
                setFormData(dupObj);
              }}
              sx={{
                "& .MuiInputLabel-shrink": {
                  color: "#41c1ba !important",
                },
                "& fieldset": {
                  borderColor: "#41c1ba !important",
                  "& legend": {
                    "& span": {
                      color: "#41c1ba !important",
                    },
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#41c1ba",
                },
                width: "200px",
              }}
            />
            {!validated &&
              (formData.fullName === null || formData.fullName === "") && (
                <div style={{ color: "red", fontSize: "small" }}>
                  Name is required
                </div>
              )}
          </Grid>
          <Grid item>
            <TextField
              label="Email"
              size="small"
              value={formData.email}
              onChange={(e) => {
                let dupObj = { ...formData };
                dupObj.email = e.target.value;
                setFormData(dupObj);
              }}
              sx={{
                "& .MuiInputLabel-shrink": {
                  color: "#41c1ba !important",
                },
                "& fieldset": {
                  borderColor: "#41c1ba !important",
                  "& legend": {
                    "& span": {
                      color: "#41c1ba !important",
                    },
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#41c1ba",
                },
                width: "200px",
              }}
            />
            {!validated &&
              !formData.email.match(
                /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
              ) && (
                <div style={{ color: "red", fontSize: "small" }}>
                  InValid Email
                </div>
              )}
          </Grid>

          {user === null && (
            <Grid item>
              <TextField
                label="Password"
                size="small"
                type={"text"}
                value={formData.password}
                onChange={(e) => {
                  let dupObj = { ...formData };
                  dupObj.password = e.target.value;
                  setFormData(dupObj);
                }}
                sx={{
                  "& .MuiInputLabel-shrink": {
                    color: "#41c1ba !important",
                  },
                  "& fieldset": {
                    borderColor: "#41c1ba !important",
                    "& legend": {
                      "& span": {
                        color: "#41c1ba !important",
                      },
                    },
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#41c1ba",
                  },
                  width: "200px",
                }}
              />
              {!validated &&
                (formData.password === null || formData.password === "") && (
                  <div style={{ color: "red", fontSize: "small" }}>
                    Password is required
                  </div>
                )}
            </Grid>
          )}          

          <Grid item>
            <TextField
              label="Username"
              size="small"
              value={formData.username}
              onChange={(e) => {
                let dupObj = { ...formData };
                dupObj.username = e.target.value;
                setFormData(dupObj);
              }}
              sx={{
                "& .MuiInputLabel-shrink": {
                  color: "#41c1ba !important",
                },
                "& fieldset": {
                  borderColor: "#41c1ba !important",
                  "& legend": {
                    "& span": {
                      color: "#41c1ba !important",
                    },
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#41c1ba",
                },
                width: "200px",
              }}
            />
            {!validated &&
              (formData.username === null || formData.username === "") && (
                <div style={{ color: "red", fontSize: "small" }}>
                  username is required
                </div>
              )}
          </Grid>
          <Grid item sm={12} md={12}>
            <h4 style={{textAlign:"center", margin:"unset"}}>Date Access</h4>
          </Grid>
          <Grid item>
              <InputLabel>Show Invoice</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                sx={{
                  padding: "unset !important",
                  width: "200px",
                }}
                components={["DatePicker"]}
              >
                <DatePicker
                  value={formData.showInvoice}
                  sx={{
                    "& input": {
                      padding: "9px !important",
                    },
                    "& .MuiInputLabel-shrink": {
                      color: "#41c1ba !important",
                      padding: "9px !important",
                    },
                    "& fieldset": {
                      borderColor: "#41c1ba !important",
                      "& legend": {
                        "& span": {
                          color: "#41c1ba !important",
                        },
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#41c1ba !important",
                    },
                  }}
                  onChange={(newValue) => {
                    const dupObj = { ...formData };
                    dupObj.showInvoice = newValue;
                    setFormData(dupObj);
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
            {!validated &&
            (formData.showInvoice === null || formData.showInvoice === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Date is required
              </div>
            )}
          </Grid>
        </Grid>
        <Box sx={{width:"100%", paddingTop:"15px"}}>
          <span style={{color:'black', fontWeight:"bold"}}>Permissions:</span>        
          <div>
            <Checkbox
            value={formData.canEdit}              
              checked={canEdit}
              onChange={handleEditChange}
              inputProps={{ "aria-label": "controlled" }}
            />
             Edit Invoice 
             </div>
    
          <div>
            <Checkbox
            value={formData.canDelete}              
              checked={canDelete}
              onChange={handleDelChange}
              inputProps={{ "aria-label": "controlled" }}
            />
            Delete Invoice
            </div>
         
          </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <Button
          variant="contained"
          sx={{
            background: "#365b6d",
            "&:hover": { backgroundColor: "#365b6d" },
          }}
          onClick={handleSubmit}
        >
          {user === null ? "Add Sub-Admin" : "Update Sub-Admin"}
        </Button>
      </DialogActions>
      <Toaster
        toastOptions={{
          duration: 1500,
          style: {
            background: "#365b6d",
            color: "#fff",
          },
        }}
      />
    </Dialog>
  );
}

export default SubAdminModal;
