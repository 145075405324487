import { Box } from "@mui/material";
import React, { useState } from "react";
import Logo from "../images/LOGO.png";
import { showToast } from "../Utils/index";
import Request from "../Utils/Request/Request";
import MobLogo from "../images/logoCrop.png";

function ForgetPassword() {
    const [email, setEmail] = useState("");

    function sendEmail(e) {
        e.preventDefault()
        if (email !== "" ) {
          Request.post("/forgotPassword", {
            email: email,
          })
            .then((err) => {
                console.log(err);
                
              showToast("success", err?.data?.message);
            })
            .catch((err) => {
              console.log("Error: ", err);
              showToast("error", err?.response?.data?.message);
            });
          // check for role and send to reports if storeAdmin
        } else {
          showToast("error", "Email Cannot be Blank");
        }
    }
  return (
    <div className="login">
      <div className="MobLogo">
            <img src={MobLogo} alt="" className="MobLogoImg" />
          </div>
    <Box className="container" id="container">
      <div className="form-container log-in-container">
        <form className="form">
          <h4>To reset password, Enter email.</h4>

          <input
            type="email"
            placeholder="Email"
            onChange={(event) => setEmail(event.target.value)}
          />
          
          <button type="submit" onClick={sendEmail}>Send Email</button>
        </form>
      </div>
      <div className="overlay-container">
        <div className="overlay">
          <div className="overlay-panel overlay-right">
            <img className="Login-img" src={Logo} alt="" />
          </div>
        </div>
      </div>
    </Box>
  </div>
  );
}

export default ForgetPassword;
