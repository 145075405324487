import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://api.doryalinvoices.com',
  // baseURL: 'http://localhost:3000',
  // baseURL: 'https://api.testdoryalaa.pro',
});

axiosInstance.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    if (error !== null && error?.response?.status === 401) {
      window.localStorage.removeItem("p_u_t");
      delete axiosInstance.defaults.headers.common.Authorization;
      window.localStorage.removeItem("userInfo");
      window.location.reload();
    }
    
    return Promise.reject((error) || 'Something went wrong!');
  }
);

export default axiosInstance;
