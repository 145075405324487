import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Divider } from "@mui/material";

function SalesReturnConfirm({ moveToReturn, currentInvoice, openSalesModal, setOpenSalesModal }) {
    
    console.log("hhjkj", currentInvoice);
    
  return (
    <Box>
      <Dialog
        open={openSalesModal}
        //   onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{fontSize:"16px"}}>Are you sure you want to move invoice <span style={{fontWeight:"bold"}}>'{currentInvoice?.invoiceNumber}'</span> to sales return?</DialogTitle>
        <Divider />
        <DialogActions>
          <Button onClick={() => setOpenSalesModal(false)}>Cancel</Button>
          <Button onClick={() => moveToReturn(currentInvoice?.id) }>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default SalesReturnConfirm;
