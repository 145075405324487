import React, { useState, useContext, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";
import Request from "../Utils/Request/Request";
import { showToast } from "../Utils/index";
import toast, { Toaster } from "react-hot-toast";
import { AppStorage } from "../Utils/Context/AppContext";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

function UserModal({ close, open, user, setForceUpdate }) {
  const initialState = {
    fullName: "",
    email: "",
    password: "",
    companyName: "",
    companyNameAR: "",
    companyNumber: null,
    vat: "",
    vatNumber: null,
    invoiceType: "",
    address: "",
    phoneNumber: null,
    invoiceNumberEdit: false,
    addField: false,
    addDiscount: true,
    username: "",
    isActive: true,
    logo: null,
    showInvoice: null,
    createInvoice: null,
    offerPrice: null,
    purchaseSupplierAccess: null,
    showTotalCalculator: null,
    notifyText: "",
  };
  const [canEdit, setCanEdit] = useState(false);
  const [addFields, setAddFields] = useState(false);
  const [addDiscounts, setAddDiscounts] = useState(true);
  const [checked, setChecked] = useState(true);
  const [formData, setFormData] = useState(initialState);
  const [validated, setValidated] = useState(true);
  const [templates, setTemplates] = useState([]);
  const AppContext = useContext(AppStorage);

  const handleEditChange = (event) => {
    let dupObj = { ...formData };
    dupObj.invoiceNumberEdit = event.target.checked;
    setCanEdit(event.target.checked);
    setFormData(dupObj);
  };
  const handleAddField = (event) => {
    let dupObj = { ...formData };
    dupObj.addField = event.target.checked;
    setAddFields(event.target.checked);
    setFormData(dupObj);
  };
  const handleAddDiscounts = (event) => {
    let dupObj = { ...formData };
    dupObj.addDiscount = event.target.checked;
    setAddDiscounts(event.target.checked);
    setFormData(dupObj);
  };
  const handleChange = (event) => {
    let dupObj = { ...formData };
    dupObj.isActive = event.target.checked;
    setChecked(event.target.checked);
    setFormData(dupObj);
  };

  useEffect(() => {
    if (user) {
      let dupObj = { ...formData };
      dupObj.fullName = user["fullName"];
      dupObj.email = user["email"];
      dupObj.vatNumber = user["vatNumber"];
      dupObj.companyName = user["companyName"];
      dupObj.companyNameAR = user["companyNameAR"];
      dupObj.companyNumber = user["companyNumber"];
      dupObj.username = user["username"];
      dupObj.password = user["password"];
      dupObj.phoneNumber = user["phoneNumber"];
      dupObj.notifyText = user["notifyText"];
      dupObj.address = user["address"];
      if (user["showInvoice"] !== null) {
        dupObj.showInvoice = dayjs(user["showInvoice"]);
      }
      if (user["createInvoice"] !== null) {
        dupObj.createInvoice = dayjs(user["createInvoice"]);
      }
      if (user["offerPrice"] !== null) {
        dupObj.offerPrice = dayjs(user["offerPrice"]);
      }
      if (user["purchaseSupplierAccess"] !== null) {
        dupObj.purchaseSupplierAccess = dayjs(user["purchaseSupplierAccess"]);
      }
      
      if (user["showTotalCalculator"] !== null) {
        dupObj.showTotalCalculator = dayjs(user["showTotalCalculator"]);
      }
      dupObj.isActive = user["isActive"];
      dupObj.invoiceType = user["invoiceType"];
      dupObj.vat = user["vat"];
      dupObj.logo = user["logo"];
      dupObj.invoiceNumberEdit = user["invoiceNumberEdit"];
      setFormData(dupObj);
      setChecked(user["isActive"]);
      setCanEdit(user["invoiceNumberEdit"]);
      setAddDiscounts(user["addDiscount"]);
      setAddFields(user["addField"]);
    }
  }, []);

  const onFileChange = (e) => {
    if (e.target && e.target.files[0]) {
      let dupObj = { ...formData };
      dupObj.logo = e.target.files[0];
      setFormData(dupObj);
    }
  };

  const validateFormData = () => {
    const vatNumberPattern = /^3.{13}3$/;
    if (
      formData.fullName === "" ||
      formData.password === "" ||
      formData.logo === null ||
      formData.vat === "" ||
      formData.vat === null ||
      formData.companyName === "" ||
      formData.companyNameAR === "" ||
      formData.companyNumber === null ||
      formData.vatNumber === null ||
      // formData.showInvoice === "" ||
      // formData.showInvoice === null ||
      // formData.createInvoice === "" ||
      // formData.createInvoice === null ||
      // formData.offerPrice === "" ||
      // formData.offerPrice === null ||
      // formData.purchaseSupplierAccess === "" ||
      // formData.purchaseSupplierAccess === null ||
      // formData.showTotalCalculator === "" ||
      // formData.showTotalCalculator === null ||
      vatNumberPattern.test(formData.vatNumber) === false ||
      formData.address === "" ||
      formData.username === "" ||
      formData.invoiceType === "" ||
      formData.email === "" ||
      !formData.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    ) {
      setValidated(false);
      return false;
    }
    setValidated(true);
    return true;
  };

  const handleSubmit = () => {
    if (validateFormData()) {
      let tempData = new FormData();
      tempData.append("fullName", formData.fullName);
      tempData.append("email", formData.email);
      tempData.append("password", formData.password);
      tempData.append("companyName", formData.companyName);
      tempData.append("companyNameAR", formData.companyNameAR);
      tempData.append("companyNumber", formData.companyNumber);
      tempData.append("vat", formData.vat);
      tempData.append("vatNumber", formData.vatNumber);
      tempData.append("notifyText", formData.notifyText);
      let showInvoiceDate;
      if (formData.showInvoice) {
        showInvoiceDate = new Date(formData.showInvoice);
      } else {
        showInvoiceDate = new Date('2099-12-12');
      }
      showInvoiceDate.setUTCHours(0, 0, 0, 0);
      tempData.append("showInvoice", showInvoiceDate);
      
      let createInvoiceDate;
      if (formData.createInvoice) {
        createInvoiceDate = new Date(formData.createInvoice);
      } else {
        createInvoiceDate = new Date('2099-12-12');
      }
      createInvoiceDate.setUTCHours(0, 0, 0, 0);
      tempData.append("createInvoice", createInvoiceDate);
      
      let offerPriceDate 
      if (formData.offerPrice) {
        offerPriceDate = new Date(formData.offerPrice);
      } else {
        offerPriceDate = new Date('2099-12-12');
      }
      offerPriceDate.setUTCHours(0, 0, 0, 0);
      tempData.append("offerPrice", offerPriceDate);
      
      let purchaseSupplierAccessDate; 
      if (formData.purchaseSupplierAccess) {
        purchaseSupplierAccessDate = new Date(formData.purchaseSupplierAccess);
      } else {
        purchaseSupplierAccessDate = new Date('2099-12-12');
      }
      purchaseSupplierAccessDate.setUTCHours(0, 0, 0, 0);
      tempData.append("purchaseSupplierAccess", purchaseSupplierAccessDate);
      
      let showTotalCalculatorDate;
      if (formData.showTotalCalculator) {
        showTotalCalculatorDate = new Date(formData.showTotalCalculator);
      } else {
        showTotalCalculatorDate = new Date('2099-12-12');
      }
      showTotalCalculatorDate.setUTCHours(0, 0, 0, 0);
      tempData.append("showTotalCalculator", showTotalCalculatorDate);


      tempData.append("address", formData.address);
      tempData.append("username", formData.username);
      tempData.append("phoneNumber", formData.phoneNumber);
      tempData.append("invoiceType", formData.invoiceType);
      tempData.append("isActive", formData.isActive);
      tempData.append("addField", formData.addField);
      tempData.append("addDiscount", formData.addDiscount);
      tempData.append("invoiceNumberEdit", formData.invoiceNumberEdit);

      if (formData.logo) {
        tempData.append("logo", formData.logo);
      }

      if (user == null) {
        Request.post("/createUser", tempData, {
          "Content-Type": "multipart/form-data",
        })
          .then((result) => {
            toast.success("New User Added");
            setTimeout(() => {
              setFormData(initialState);
              close();
              setForceUpdate(true);
            }, 1500);
            console.log("Data: ", result.data);
          })
          .catch((err) => {
            if (err.response.status == 400) {
              showToast("error", err.response.data.message);
            } 
            else if (err.response.status == 401) {
              showToast("error", "Unauthorized user.");
            } else {
              showToast("error", "Something went wrong.");
            }
          });
      } else {
        tempData.append("id", user["id"]);
        Request.put("/updateUser", tempData, {
          "Content-Type": "multipart/form-data",
        })
          .then((result) => {
            console.log("Data: ", result.data);
            toast.success("User Updated");
            setTimeout(() => {
              setFormData(initialState);
              close();
              setForceUpdate(true);
            }, 1500);
          })
          .catch((err) => {
            console.log("Error: ", err);
            if (err.response.status == 401) {
              showToast("error", "Unauthorized user.");
            } else {
              showToast("error", "Something went wrong.");
            }
          });
      }
    }
  };

  useEffect(() => {
    if (user === null) {
      generatePassword();
    }
  }, [open]);

  const generatePassword = () => {
    let pass = "";
    let string =
      "ABCDEFGHIJKLMNOPQRSTUVWXUZ" + "abcdefghijklmnopqrstuvwxyz123456789@#$";
    for (let i = 1; i <= 16; i++) {
      let char = Math.floor(Math.random() * string.length + 1);

      pass += string.charAt(char);
    }
    let dupObj = { ...formData };
    dupObj.password = pass;
    setFormData(dupObj);
  };
  // template names
  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await Request.get('/getAllTemplates');
        setTemplates(response.data);
      } catch (error) {
        console.error('Error fetching templates: ', error);
      }
    };

    fetchTemplates();
  }, []);

  return (
    <Dialog
      open={open}
      onClose={close}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px", // Set your width here
          },
        },
      }}
    >
      <DialogTitle>
        {user === null ? "Add New User" : "Update User"}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} style={{ marginTop: "10px" }}>
          <Grid item xs="auto">
            <TextField
              label="Full Name"
              size="small"
              value={formData.fullName}
              onChange={(e) => {
                let dupObj = { ...formData };
                dupObj.fullName = e.target.value;
                setFormData(dupObj);
              }}
              sx={{
                "& .MuiInputLabel-shrink": {
                  color: "#41c1ba !important",
                },
                "& fieldset": {
                  borderColor: "#41c1ba !important",
                  "& legend": {
                    "& span": {
                      color: "#41c1ba !important",
                    },
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#41c1ba",
                },
                width: "200px",
              }}
            />
            {!validated &&
              (formData.fullName === null || formData.fullName === "") && (
                <div style={{ color: "red", fontSize: "small" }}>
                  Name is required
                </div>
              )}
          </Grid>
          <Grid item>
            <TextField
              label="Email"
              size="small"
              value={formData.email}
              onChange={(e) => {
                let dupObj = { ...formData };
                dupObj.email = e.target.value;
                setFormData(dupObj);
              }}
              sx={{
                "& .MuiInputLabel-shrink": {
                  color: "#41c1ba !important",
                },
                "& fieldset": {
                  borderColor: "#41c1ba !important",
                  "& legend": {
                    "& span": {
                      color: "#41c1ba !important",
                    },
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#41c1ba",
                },
                width: "200px",
              }}
            />
            {!validated &&
              !formData.email.match(
                /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
              ) && (
                <div style={{ color: "red", fontSize: "small" }}>
                  Invalid email
                </div>
              )}
          </Grid>

          {user === null && (
            <Grid item>
              <TextField
                label="Password"
                size="small"
                type={"text"}
                value={formData.password}
                onChange={(e) => {
                  let dupObj = { ...formData };
                  dupObj.password = e.target.value;
                  setFormData(dupObj);
                }}
                sx={{
                  "& .MuiInputLabel-shrink": {
                    color: "#41c1ba !important",
                  },
                  "& fieldset": {
                    borderColor: "#41c1ba !important",
                    "& legend": {
                      "& span": {
                        color: "#41c1ba !important",
                      },
                    },
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#41c1ba",
                  },
                  width: "200px",
                }}
              />
              {!validated &&
                (formData.password === null || formData.password === "") && (
                  <div style={{ color: "red", fontSize: "small" }}>
                    Password is required
                  </div>
                )}
            </Grid>
          )}
          <Grid item>
            <TextField
              label="Company Name (EN)"
              size="small"
              value={formData.companyName}
              onChange={(e) => {
                let dupObj = { ...formData };
                dupObj.companyName = e.target.value;
                setFormData(dupObj);
              }}
              sx={{
                "& .MuiInputLabel-shrink": {
                  color: "#41c1ba !important",
                },
                "& fieldset": {
                  borderColor: "#41c1ba !important",
                  "& legend": {
                    "& span": {
                      color: "#41c1ba !important",
                    },
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#41c1ba",
                },
                width: "200px",
              }}
            />
            {!validated &&
              (formData.companyName === null ||
                formData.companyName === "") && (
                <div style={{ color: "red", fontSize: "small" }}>
                  Company name is required
                </div>
              )}
          </Grid>

          <Grid item>
            <TextField
              label="Company Name (AR)"
              size="small"
              value={formData.companyNameAR}
              onChange={(e) => {
                let dupObj = { ...formData };
                dupObj.companyNameAR = e.target.value;
                setFormData(dupObj);
              }}
              sx={{
                "& .MuiInputLabel-shrink": {
                  color: "#41c1ba !important",
                },
                "& fieldset": {
                  borderColor: "#41c1ba !important",
                  "& legend": {
                    "& span": {
                      color: "#41c1ba !important",
                    },
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#41c1ba",
                },
                width: "200px",
              }}
            />
            {!validated &&
              (formData.companyNameAR === null ||
                formData.companyNameAR === "") && (
                <div style={{ color: "red", fontSize: "small" }}>
                  Company name is required
                </div>
              )}
          </Grid>

          <Grid item>
            <TextField
              type={"number"}
              size="small"
              label="Company Number"
              value={formData.companyNumber}
              onChange={(e) => {
                let dupObj = { ...formData };
                dupObj.companyNumber = e.target.value;
                setFormData(dupObj);
              }}
              sx={{
                "& .MuiInputLabel-shrink": {
                  color: "#41c1ba !important",
                },
                "& fieldset": {
                  borderColor: "#41c1ba !important",
                  "& legend": {
                    "& span": {
                      color: "#41c1ba !important",
                    },
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#41c1ba",
                },
                width: "200px",
              }}
            />
            {!validated &&
              (formData.companyNumber === null ||
                formData.companyNumber === "") && (
                <div style={{ color: "red", fontSize: "small" }}>
                  Company number is required
                </div>
              )}
          </Grid>
          <Grid item>
            <TextField
              type={"number"}
              label="Vat Number"
              size="small"
              value={formData.vatNumber}
              onChange={(e) => {
                let dupObj = { ...formData };
                dupObj.vatNumber = e.target.value;
                setFormData(dupObj);
              }}
              sx={{
                "& .MuiInputLabel-shrink": {
                  color: "#41c1ba !important",
                },
                "& fieldset": {
                  borderColor: "#41c1ba !important",
                  "& legend": {
                    "& span": {
                      color: "#41c1ba !important",
                    },
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#41c1ba",
                },
                width: "200px",
              }}
            />
            {!validated &&
              (formData.vatNumber === null || formData.vatNumber === "") && (
                <div style={{ color: "red", fontSize: "small" }}>
                  Vat number is required
                </div>
              )}
            {!validated && /^3.{13}3$/.test(formData.vatNumber) === false && (
              <div style={{ color: "red" }}>
                TRN must consist of 15 digits, where the first and <br /> last
                digits are [3]
              </div>
            )}
          </Grid>
          <Grid item>
            <TextField
              type={"number"}
              label="Vat %"
              size="small"
              value={formData.vat}
              onChange={(e) => {
                let dupObj = { ...formData };
                dupObj.vat = e.target.value;
                setFormData(dupObj);
              }}
              sx={{
                "& .MuiInputLabel-shrink": {
                  color: "#41c1ba !important",
                },
                "& fieldset": {
                  borderColor: "#41c1ba !important",
                  "& legend": {
                    "& span": {
                      color: "#41c1ba !important",
                    },
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#41c1ba",
                },
                width: "200px",
              }}
            />
            {!validated && (formData.vat === null || formData.vat === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Vat Percent is required
              </div>
            )}
          </Grid>
          <Grid item>
            <TextField
              label="Username"
              size="small"
              value={formData.username}
              onChange={(e) => {
                let dupObj = { ...formData };
                dupObj.username = e.target.value;
                setFormData(dupObj);
              }}
              sx={{
                "& .MuiInputLabel-shrink": {
                  color: "#41c1ba !important",
                },
                "& fieldset": {
                  borderColor: "#41c1ba !important",
                  "& legend": {
                    "& span": {
                      color: "#41c1ba !important",
                    },
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#41c1ba",
                },
                width: "200px",
              }}
            />
            {!validated &&
              (formData.username === null || formData.username === "") && (
                <div style={{ color: "red", fontSize: "small" }}>
                  User name is required
                </div>
              )}
          </Grid>

          <Grid item>
            <TextField
              label="Address"
              size="small"
              value={formData.address}
              onChange={(e) => {
                let dupObj = { ...formData };
                dupObj.address = e.target.value;
                setFormData(dupObj);
              }}
              sx={{
                "& .MuiInputLabel-shrink": {
                  color: "#41c1ba !important",
                },
                "& fieldset": {
                  borderColor: "#41c1ba !important",
                  "& legend": {
                    "& span": {
                      color: "#41c1ba !important",
                    },
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#41c1ba",
                },
                width: "200px",
              }}
            />
            {!validated &&
              (formData.address === null || formData.address === "") && (
                <div style={{ color: "red", fontSize: "small" }}>
                  Address is required
                </div>
              )}
          </Grid>

          <Grid item>
            <TextField
              size="small"
              type={"number"}
              label="Phone Number"
              value={formData.phoneNumber}
              onChange={(e) => {
                let dupObj = { ...formData };
                dupObj.phoneNumber = e.target.value;
                setFormData(dupObj);
              }}
              sx={{
                "& .MuiInputLabel-shrink": {
                  color: "#41c1ba !important",
                },
                "& fieldset": {
                  borderColor: "#41c1ba !important",
                  "& legend": {
                    "& span": {
                      color: "#41c1ba !important",
                    },
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#41c1ba",
                },
                width: "200px",
              }}
            />
          </Grid>

          <Grid item>
            <FormControl>
              <InputLabel
                sx={{ top: "-7px !important" }}
                id="demo-simple-select-label"
              >
                Invoice Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                size="small"
                sx={{
                  width: "200px",
                  "& .MuiInputLabel-shrink": {
                    color: "#41c1ba !important",
                  },
                  "& fieldset": {
                    borderColor: "#41c1ba !important",
                    "& legend": {
                      "& span": {
                        color: "#41c1ba !important",
                      },
                    },
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#41c1ba",
                  },
                }}
                label="Invoice Type"
                onChange={(e) => {
                  const dupObj = { ...formData };
                  dupObj.invoiceType = e.target.value;
                  setFormData(dupObj);
                }}
                value={formData.invoiceType}
              >
                <MenuItem value={"simple"}>Simple</MenuItem>
                <MenuItem value={"normal"}>Normal</MenuItem>
                {templates.map((template) => (
              <MenuItem key={template.id} value={template.name}>
                {template.name}
              </MenuItem>
            ))}
              </Select>
            </FormControl>
            {!validated &&
              (formData.invoiceType === null ||
                formData.invoiceType === "") && (
                <div style={{ color: "red", fontSize: "small" }}>
                  Select Invoice Type
                </div>
              )}
          </Grid>
          <Grid item>
            <TextField
              type={"file"}
              size="small"
              onChange={onFileChange}
              sx={{
                "& .MuiInputLabel-shrink": {
                  color: "#41c1ba !important",
                },
                "& fieldset": {
                  borderColor: "#41c1ba !important",
                  "& legend": {
                    "& span": {
                      color: "#41c1ba !important",
                    },
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#41c1ba",
                },
                width: "200px",
              }}
            />
            {!validated && (formData.logo === null || formData.logo === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Company logo is required
              </div>
            )}
          </Grid>
          
          <Grid item>
            <TextField
              label="NotifyText"
              size="small"
              value={formData.notifyText}
              onChange={(e) => {
                let dupObj = { ...formData };
                dupObj.notifyText = e.target.value;
                setFormData(dupObj);
              }}
              sx={{
                "& .MuiInputLabel-shrink": {
                  color: "#41c1ba !important",
                },
                "& fieldset": {
                  borderColor: "#41c1ba !important",
                  "& legend": {
                    "& span": {
                      color: "#41c1ba !important",
                    },
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#41c1ba",
                },
                width: "200px",
              }}
            />
          </Grid>
          <Grid item>
            <Switch
              value={formData.isActive}
              checked={checked}
              onChange={handleChange}
            />
            Active
          </Grid>
          <Grid item>
            <Switch
              value={formData.invoiceNumberEdit}
              checked={canEdit}
              onChange={handleEditChange}
            />
            Invoice Number
          </Grid>
          <Grid item>
            <Switch
              value={formData.addField}
              checked={addFields}
              onChange={handleAddField}
            />
            Add Fields
          </Grid>
          <Grid item>
            <Switch
              value={formData.addDiscount}
              checked={addDiscounts}
              onChange={handleAddDiscounts}
            />
            Allow Discounts
          </Grid>
          <Grid item sm={12} md={12}>
            <h4 style={{textAlign:"center", margin:"unset"}}>Date Access</h4>
          </Grid>
          <Grid item>
              <InputLabel>Show Invoice</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                sx={{
                  padding: "unset !important",
                  width: "200px",
                }}
                components={["DatePicker"]}
              >
                <DatePicker
                  value={formData.showInvoice}
                  sx={{
                    "& input": {
                      padding: "9px !important",
                    },
                    "& .MuiInputLabel-shrink": {
                      color: "#41c1ba !important",
                      padding: "9px !important",
                    },
                    "& fieldset": {
                      borderColor: "#41c1ba !important",
                      "& legend": {
                        "& span": {
                          color: "#41c1ba !important",
                        },
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#41c1ba !important",
                    },
                  }}
                  onChange={(newValue) => {
                    const dupObj = { ...formData };
                    dupObj.showInvoice = newValue;
                    setFormData(dupObj);
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
            {/* {!validated &&
            (formData.showInvoice === null || formData.showInvoice === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Date is required
              </div>
            )} */}
          </Grid>
          <Grid item>
          <InputLabel>Create Invoice</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                sx={{
                  padding: "unset !important",
                  width: "200px",
                }}
                components={["DatePicker"]}
              >
                <DatePicker
                  value={formData.createInvoice || null}
                  sx={{
                    "& input": {
                      padding: "9px !important",
                    },
                    "& .MuiInputLabel-shrink": {
                      color: "#41c1ba !important",
                      padding: "9px !important",
                    },
                    "& fieldset": {
                      borderColor: "#41c1ba !important",
                      "& legend": {
                        "& span": {
                          color: "#41c1ba !important",
                        },
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#41c1ba !important",
                    },
                  }}
                  onChange={(newValue) => {
                    const dupObj = { ...formData };
                    dupObj.createInvoice = newValue;
                    setFormData(dupObj);
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
            {/* {!validated &&
            (formData.createInvoice === null || formData.createInvoice === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Date is required
              </div>
            )} */}
          </Grid>
          <Grid item>
          <InputLabel>Offer Price</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                sx={{
                  padding: "unset !important",
                  width: "200px",
                }}
                components={["DatePicker"]}
              >
                <DatePicker
                  value={formData.offerPrice || null}
                  sx={{
                    "& input": {
                      padding: "9px !important",
                    },
                    "& .MuiInputLabel-shrink": {
                      color: "#41c1ba !important",
                      padding: "9px !important",
                    },
                    "& fieldset": {
                      borderColor: "#41c1ba !important",
                      "& legend": {
                        "& span": {
                          color: "#41c1ba !important",
                        },
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#41c1ba !important",
                    },
                  }}
                  onChange={(newValue) => {
                    const dupObj = { ...formData };
                    dupObj.offerPrice = newValue;
                    setFormData(dupObj);
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
            {/* {!validated &&
            (formData.offerPrice === null || formData.offerPrice === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Date is required
              </div>
            )} */}
          </Grid>
          <Grid item>
          <InputLabel>Purchase & Supplier</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                sx={{
                  padding: "unset !important",
                  width: "200px",
                }}
                components={["DatePicker"]}
              >
                <DatePicker
                  value={formData.purchaseSupplierAccess || null}
                  sx={{
                    "& input": {
                      padding: "9px !important",
                    },
                    "& .MuiInputLabel-shrink": {
                      color: "#41c1ba !important",
                      padding: "9px !important",
                    },
                    "& fieldset": {
                      borderColor: "#41c1ba !important",
                      "& legend": {
                        "& span": {
                          color: "#41c1ba !important",
                        },
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#41c1ba !important",
                    },
                  }}
                  onChange={(newValue) => {
                    const dupObj = { ...formData };
                    dupObj.purchaseSupplierAccess = newValue;
                    setFormData(dupObj);
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
            {/* {!validated &&
            (formData.purchaseSupplierAccess === null || formData.purchaseSupplierAccess === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Date is required
              </div>
            )} */}
          </Grid>
          <Grid item>
          <InputLabel>Total Calculator</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                sx={{
                  padding: "unset !important",
                  width: "200px",
                }}
                components={["DatePicker"]}
              >
                <DatePicker
                  value={formData.showTotalCalculator || null}
                  sx={{
                    "& input": {
                      padding: "9px !important",
                    },
                    "& .MuiInputLabel-shrink": {
                      color: "#41c1ba !important",
                      padding: "9px !important",
                    },
                    "& fieldset": {
                      borderColor: "#41c1ba !important",
                      "& legend": {
                        "& span": {
                          color: "#41c1ba !important",
                        },
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#41c1ba !important",
                    },
                  }}
                  onChange={(newValue) => {
                    const dupObj = { ...formData };
                    dupObj.showTotalCalculator = newValue;
                    setFormData(dupObj);
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
            {/* {!validated &&
            (formData.showTotalCalculator === null || formData.showTotalCalculator === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Date is required
              </div>
            )} */}
          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <Button
          variant="contained"
          sx={{
            background: "#365b6d",
            "&:hover": { backgroundColor: "#365b6d" },
          }}
          onClick={handleSubmit}
        >
          {user === null ? "Add User" : "Update User"}
        </Button>
      </DialogActions>
      <Toaster
        toastOptions={{
          duration: 1500,
          style: {
            background: "#365b6d",
            color: "#fff",
          },
        }}
      />
    </Dialog>
  );
}

export default UserModal;
