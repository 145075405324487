import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Request from "../Utils/Request/Request";
import { useState, useContext, useEffect } from "react";
import { AppStorage } from "../Utils/Context/AppContext";
import { showToast } from "../Utils/index";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import DeleteModal from "./DeleteModal";
import { FormControl, MenuItem, Select, TextField, Typography } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Loader from "./Loader";
import { useTranslation } from "react-i18next";
import SupplierModal from "../modals/SupplierModal";

function AllSuppliers() {
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openDelModal, setOpenDelModal] = useState(false);

  const OpenDelModal = (user) => {
    setOpenDelModal(true);
    setDelSupplier(user);
  };

  const CloseDelModal = () => {
    setOpenDelModal(false);
  };

  const [delSupplier, setDelSupplier] = useState("");
  const [suppliers, setSuppliers] = useState([]);
  const [supplierToUpdate, setSupplierToUpdate] = useState(null);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 7,
  });
  const handleChange = (event, value) => {
    let dupPagination = { ...pagination };
    dupPagination.current = value;
    setPagination(dupPagination);
  };
  const [searchPhrase, setSearchPhrase] = useState("");

  const AppContext = useContext(AppStorage);

  useEffect(() => {
    if(AppContext?.userAuthToken || AppContext?.userAuthToken !== ""){
    getData({ pagination, searchPhrase });
    }
  }, [AppContext?.userAuthToken, pagination?.current, i18n.language]);
  
  useEffect(() => {
    if (forceUpdate) {
      getData({ pagination, searchPhrase });
    }
  }, [forceUpdate]);

  function getData(params) {
    setLoading(true);
    let paramsData = {};

    paramsData = {
      ...paramsData,
      searchPhrase: params.searchPhrase,
    };

    Request.get(`/getSuppliers/${params.pagination?.current}`, paramsData
    )
      .then((result) => {
        console.log("Data: ", result);
        setLoading(false);
        setSuppliers(result.data["data"]);
        setForceUpdate(false);
        setPagination({
          ...params.pagination,
          total: result.data["totalCount"],
        });
        setSearchPhrase(params.searchPhrase);
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }

  function searchChange(value) {
    getData({ pagination, searchPhrase: value });
  }
 // Function to recalculate row numbers
 const updateRowNumbers = () => {
  const suppliersWithRowNumber = suppliers.map((supplier, index) => {
    return { ...supplier, rowNumber: (pagination.current - 1) * 7 + (index + 1) };
  });
  setSuppliers(suppliersWithRowNumber);
};

// Function to handle row deletion
const handleRowDelete = (selectedRowIds) => {
  const updatedSuppliers = suppliers.filter(
    (supplier) => !selectedRowIds.includes(supplier.id)
  );
  setSuppliers(updatedSuppliers);
  updateRowNumbers(); // Recalculate row numbers after deletion
};
  const columns = [
    { field: "rowNumber", headerName: `${t('SR-NO.')}`, width: 70 },
    { field: "supplierName", headerName: `${t("Supplier Name")}`, width: 200 },
    { field: "vatNumber", headerName: `${t("Vat Number")}`, width: 200 },
    { field: "address", headerName: `${t("Address")}`, width: 200 },
    { field: "phoneNumber", headerName: `${t("Phone Number")}`, width: 200 },
    {
      headerName: `${t("Actions")}`,
      renderCell: (record) => {
        return (
          <Box sx={{ display: "flex", gap: "8px" }}>
            <EditIcon
              onClick={() => UpdateSupplierModal(record.row)}
              sx={{ cursor: "pointer", color: "#41c1ba" }}
            />
            <DeleteIcon
              sx={{ cursor: "pointer", color: "#365b6d" }}
              onClick={() => OpenDelModal(record.row)}
            />
          </Box>
        );
      },
    },
  ];

  function NewSupplierModal() {
    setOpen(true);
    setSupplierToUpdate(null);
  }

  function UpdateSupplierModal(supplier) {
    setOpen(true);
    setSupplierToUpdate(supplier);
  }

  function deleteSupplier(supplier) {
    Request.delete(`/delSupplier/${supplier["id"]}`)
      .then(() => {
        setForceUpdate(true);
        CloseDelModal();
        showToast("success", "Deleted Successfully.");
      })
      .catch((err) => {
        console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }

  const changeLanguageHandler = (e) => {
    const languageValue = e.target.value
    i18n.changeLanguage(languageValue);

    localStorage.setItem("selectedLanguage", languageValue);
  }

  const normalizeDate = (date) => {
    const normalized = new Date(date);
    normalized.setUTCHours(0, 0, 0, 0);
    return normalized;
  };
  const accessDate =  AppContext?.userInfo?.purchaseSupplierAccess ? new Date(AppContext?.userInfo?.purchaseSupplierAccess) : null;
  const currentDate = normalizeDate(new Date()); 

  return (
    <Box>
      {(accessDate === null || accessDate >= currentDate) && (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          marginBottom: "8px",
        }}
      >
        <TextField
          type="text"
          placeholder={t("Search By Supplier Name")}
          onChange={(event) => searchChange(event.target.value)}
          sx={{
            width: "250px",
            marginRight: "20px",
            "& .MuiOutlinedInput-input": { height: "0.5em" },
            "& fieldset": {
              borderColor: "#365b6d !important",
            },
          }}
        />

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Button
        className="largeScreen-btn"
          onClick={NewSupplierModal}
          variant="contained"
          sx={{
            background: "#365b6d",
            "&:hover": { backgroundColor: "#365b6d" },
          }}
        >
        {t("Add New Supplier")}
        </Button>
        </Box>
        <Button
        className="smallScreen-btn"
          onClick={NewSupplierModal}
          variant="contained"
          sx={{
            background: "#365b6d",
            "&:hover": { backgroundColor: "#365b6d" },
            fontSize:"30px",
            height: "40px",
          }}
        >
          +
        </Button>
      </Box>
      )}
        <div style={{height: "calc(100vh - 235px)", width: "100%" }}>
          <Typography
            variant="h6"
            sx={{ color: "#365b6d", textAlign: "center" }}
          >
            {t("All Suppliers")}
          </Typography>
          <>
          {(accessDate === null || accessDate >= currentDate) ? (
          loading ? (
        <Loader />
      ) : (
        <>
          <DataGrid
            rows={suppliers.map((supplier, index) => ({ ...supplier, rowNumber: (pagination.current - 1) * 7 + (index + 1) }))}
            columns={columns}
            hideFooter
            disableColumnMenu
            checkboxSelection={false}
            disableSelectionOnClick
            onSelectionModelChange={(newSelection) => handleRowDelete(newSelection)}
            sx={{background:"white"}}
          />
          <Stack spacing={2} marginTop="5px">
            <Pagination
              count={Math.ceil(pagination?.total / 7)}
              page={pagination?.current}
              onChange={handleChange}
            />
          </Stack>
          </>
        )
      ) : (
        <div style={{display:"flex", alignItems:"center", justifyContent:"center", height:"100%"}}>
          <p style={{color:"rgb(61 145 255)"}}>{t("Unfortunately, this service cannot be accessed. Contact the support person responsible for your account")}</p></div>
      )}
    </>
        </div>
      {open && (
        <SupplierModal
          close={handleClose}
          open={open}
          handleClickOpen={handleClickOpen}
          supplier={supplierToUpdate}
          setForceUpdate={setForceUpdate}
        />
      )}

      {openDelModal && (
        <DeleteModal
          del={deleteSupplier}
          currentUser={delSupplier}
          openDelModal={openDelModal}
          setDeleteModal={setOpenDelModal}
        />
      )}
    </Box>
  );
}

export default AllSuppliers;
