import React, { forwardRef, useEffect, useContext, useState } from "react";
import Logo from "../images/logoCrop.png";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { AppStorage } from "../Utils/Context/AppContext";
import Request from "../Utils/Request/Request";
import { showToast } from "../Utils";
import moment from "moment";

const InvoiceHtml = forwardRef(({ flag, sales }, ref) => {
  const { id } = useParams();
  const AppContext = useContext(AppStorage);
  const [invoiceData, setInvoiceData] = useState(null);
  const [userData, setUserData] = useState(null);
  console.log("ddd", flag, "ssss", sales);

  function getPrintInvoice() {
    Request.get(`/getPrintInvoice/${id}`)
      .then((result) => {
        setInvoiceData(result.data.invoice);
        setUserData(result.data.UserData);
      })
      .catch((err) => {
        console.log("Error: ", err);
        if (err.response.status === 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }
  useEffect(() => {
    if (
      (invoiceData?.companyName && invoiceData?.invoiceNumber) ||
      (invoiceData?.companyName && invoiceData?.offerNumber) ||
      (invoiceData?.supplierName && invoiceData?.supplierNumber)
    ) {
      document.title =
        flag === true && sales === false
          ? `${invoiceData?.companyName}_${invoiceData.offerNumber}`
          : flag === "purchases" ? `${invoiceData?.supplierName}_${invoiceData.supplierNumber}`
          : flag === false && sales === true
          ? `return_${invoiceData.invoiceNumber}_${invoiceData?.companyName}`
          : `${invoiceData?.companyName}_${invoiceData.invoiceNumber}`;
    }
  }, [invoiceData]);

  useEffect(() => {
    if (AppContext?.userAuthToken || AppContext?.userAuthToken !== "") {
      getPrintInvoice();
    }
  }, [AppContext?.userInfo, AppContext?.userAuthToken]);

  const totalVat = invoiceData?.invoiceproducts
  ?.reduce((accumulator, item) => accumulator + parseFloat(item?.vat || 0), 0)
  .toFixed(2);

const totalVatAsNumber = parseFloat(totalVat);

  const totalExtraValues = invoiceData?.extravalues?.reduce((sum, item) => sum + (item?.value || 0), 0) || 0;
  // const totalExtraValuesDisc = totalExtraValues - invoiceData?.discount;
  const netAmount = AppContext?.userInfo?.addDiscount === true ? ((invoiceData?.totalAfterVat || 0) - totalExtraValues) : ((invoiceData?.total + totalVatAsNumber) - totalExtraValues);
  return (
    <Box sx={{ backgroundColor: "#fff" }} ref={ref}>
      <Grid container spacing={1} sx={{ width: "100%", padding: "2px 32px" }}>
        <Grid item xs={5} sx={{ width: "100%" }}>
          <h3>{userData?.companyName}</h3>
          <h3>C.R: {userData?.companyNumber}</h3>
          <h3>VAT: {userData?.vatNumber} </h3>
        </Grid>

        <Grid item xs={2} sx={{ width: "100%" }}>
          <img
            style={{ width: "100%" }}
            src={
              userData?.logo !== "" && userData?.logo !== null && userData?.logo
                ? `https://api.doryalinvoices.com/image-storage/${userData?.logo}`
                : Logo
            }
            alt=""
          />
        </Grid>

        <Grid item xs={5} sx={{ width: "100%", textAlign: "right" }}>
          <h3>{userData?.companyNameAR}</h3>
          <h3> {userData?.companyNumber} :س.ت</h3>
          <h3>{userData?.vatNumber} :رقم ضريبي </h3>
        </Grid>
      </Grid>
      {flag === true && sales == false && (
        <h2 style={{ textAlign: "center" }}> Price Offer - عرض سعر</h2>
      )}
      {flag === false && sales == false && (
        <h2 style={{ textAlign: "center" }}> TAX INVOICE – فاتورة ضريبية</h2>
      )}
      {flag === false && sales == true && (
        <h2 style={{ textAlign: "center" }}>
          {" "}
          مرتجع مبيعات / اشعار دائن <br /> Sales Return
        </h2>
      )}
      {flag === "purchases" && (
        <h2 style={{ textAlign: "center" }}>
          {" "}
          فاتورة مشتريات <br /> Purchases Invoices
        </h2>
      )}
      <div style={{ width: "100%" }}>
        <table style={{ margin: "auto", fontSize: "14px" }}>
          <tr>
            <td className="tdInvoice" style={{ fontWeight: "bold" }}>
              {flag === true
                ? "NO: /offer رقم العرض"
                : "رقم الفاتورة/INVOICE NO:"}
            </td>
            <td className="tdInvoice">
              {flag === true && invoiceData?.offerNumber}
              {flag === false && invoiceData?.invoiceNumber}
              {flag === "purchases" && invoiceData?.supplierNumber}
            </td>
            <td className="tdInvoice" style={{ fontWeight: "bold" }}>
              Vat NO /الرقم الضريبي
            </td>
            <td className="tdInvoice">{invoiceData?.vatNumber}</td>
          </tr>
          <tr>
            <td className="tdInvoice" style={{ fontWeight: "bold" }}>
              {flag === true
                ? "Offer Date/تاريخ العرض"
                : "تاريخ الفاتورة / INVOICE DATE:"}
            </td>
            <td className="tdInvoice">
              {moment(invoiceData?.invoiceDate).format("YYYY-MM-DD")}
            </td>
            {/* customer address */}
            <td className="tdInvoice" style={{ fontWeight: "bold" }}>
              {flag === true
                ? "Client Address/موقع العميل"
                : "CUSTOMER Address /عنوان العميل"}{" "}
            </td>
            <td className="tdInvoice">{invoiceData?.address}</td>
          </tr>
          <tr>
            <td className="tdInvoice" style={{ fontWeight: "bold" }}>
              {" "}
              {flag === true && "Client/العميل"}
              {flag === false && " العميل/ CUSTOMER NAME:"}
              {flag === "purchases" && "اسم المورد / Supplier NAME:"}
            </td>
            <td className="tdInvoice">
              {flag === "purchases"
                ? invoiceData?.supplierName
                : invoiceData?.companyName}
            </td>
            {/* PO NO. */}
            {flag === true ? (
              <>
                <td className="tdInvoice" style={{ fontWeight: "bold" }}>
                  validity / الصالحية
                </td>
                <td className="tdInvoice">{invoiceData?.validity}</td>
              </>
            ) : (
              <>
                <td className="tdInvoice" style={{ fontWeight: "bold" }}>
                  طريقة الدفع / PAYMENT TERMS:{" "}
                </td>
                <td className="tdInvoice">{invoiceData?.payment}</td>
              </>
            )}
          </tr>
          <tr>
            {/* {flag === true && ( */}
            <>
              <td className="tdInvoice" style={{ fontWeight: "bold" }}>
                notes / ملاحظة
              </td>
              <td className="tdInvoice">{invoiceData?.notes}</td>
            </>
            <>
              <td className="tdInvoice" style={{ fontWeight: "bold" }}>
                notes2 /ملاحظة 2
              </td>
              <td className="tdInvoice">{invoiceData?.notes_2}</td>
            </>
            {/* )} */}
          </tr>
          <tr>
            {flag === false && (
              <>
                <td className="tdInvoice" style={{ fontWeight: "bold" }}>
                  PO NO / المرجع
                </td>
                <td className="tdInvoice">{invoiceData?.po}</td>
              </>
            )}
          </tr>
        </table>
      </div>
      {/* items in bill */}
      <Box
        sx={{
          borderBottom: "2px dashed black",
          width: "700px",
          margin: "auto",
          marginTop: "30px",
        }}
      ></Box>
      {/* Products purchased */}
      <div
        style={{
          width: "100%",
          marginTop: "40px",
          pageBreakAfter: "auto",
        }}
      >
        <table style={{ margin: "auto" }}>
          <tr>
            <th className="thInvoice" style={{ textAlign: "center" }}>
              Sr.No <br />
              رقم
            </th>
            <th className="thInvoice" style={{ textAlign: "center" }}>
              Product Name <br /> المنتج
            </th>
            <th className="thInvoice" style={{ textAlign: "center" }}>
              Description <br /> الوصف
            </th>
            <th className="thInvoice" style={{ textAlign: "center" }}>
              Unit
              <br /> الوحدة
            </th>
            <th className="thInvoice" style={{ textAlign: "center" }}>
              Qty <br /> الكمية
            </th>
            <th className="thInvoice" style={{ textAlign: "center" }}>
              Unit Price <br /> سعر الوحدة
            </th>

            <th className="thInvoice" style={{ textAlign: "center" }}>
              Total <br /> المجموع
            </th>
            {AppContext?.userInfo?.addDiscount === true && (
              <>
                <th className="thInvoice" style={{ textAlign: "center" }}>
                  Discount <br /> الخصم
                </th>
                <th className="thInvoice" style={{ textAlign: "center" }}>
                  Total After Discount <br /> المجموع بعد الخصم
                </th>
              </>
            )}
            <th className="thInvoice" style={{ textAlign: "center" }}>
              vat {AppContext?.userInfo?.vat}% <br /> الضريبة
            </th>

            <th className="thInvoice" style={{ textAlign: "center" }}>
              Total After Vat
              <br /> المجموع بعد الضرببة
            </th>
          </tr>
          {!!invoiceData?.invoiceproducts?.length &&
            invoiceData?.invoiceproducts?.map((item, i) => (
              <tr key={i}>
                <td className="tdInvoice" style={{ textAlign: "center" }}>
                  {i + 1}
                </td>
                <td className="tdInvoice" style={{ textAlign: "center" }}>
                  {item?.productName}
                </td>
                <td
                  className="thInvoice"
                  style={{ padding: "0px 0px 0px 5px" }}
                >
                  {item?.description}
                </td>
                <td className="tdInvoice" style={{ textAlign: "center" }}>
                  {item?.unit}
                </td>
                <td className="tdInvoice" style={{ textAlign: "center" }}>
                  {item?.qty}
                </td>
                <td className="tdInvoice" style={{ textAlign: "center" }}>
                  {item?.unitPrice}
                </td>

                <td className="tdInvoice" style={{ textAlign: "center" }}>
                  {item?.total}
                </td>
                {AppContext?.userInfo?.addDiscount === true && (
                  <>
                    <td className="tdInvoice" style={{ textAlign: "center" }}>
                      {item?.discount}
                    </td>
                    <td className="tdInvoice" style={{ textAlign: "center" }}>
                      {item?.totalAfterDiscount}
                    </td>
                  </>
                )}
                <td className="tdInvoice" style={{ textAlign: "center" }}>
                  {item?.vat}
                </td>

                <td className="tdInvoice" style={{ textAlign: "center" }}>
                {AppContext?.userInfo?.addDiscount === true ? item?.totalAfterVat : item?.total + item?.vat }
                </td>
              </tr>
            ))}
        </table>
      </div>

      <div
        style={{
          width: "fit-content",
          marginTop: "40px",
          marginBottom: "25px",
          marginLeft: "auto",
          marginRight: "auto",
          pageBreakBefore: "auto",
        }}
      >
        <div style={{ display: "flex", gap: "25px" }}>
          <table style={{ margin: "auto", fontSize: "14px", width: "490px" }}>
            <tr>
              <td className="thInvoice" style={{ fontWeight: "bolder" }}>
                Total Before Vat / الاجمالي قبل الضريية:
              </td>
              <td className="tdInvoice">{invoiceData?.total}</td>
            </tr>
            {AppContext?.userInfo?.addDiscount === true && (
              <>
                <tr>
                  <td className="thInvoice" style={{ fontWeight: "bolder" }}>
                    Discount / الخصم:
                  </td>
                  <td className="tdInvoice">{invoiceData?.discount}</td>
                </tr>
                <tr>
                  <td className="thInvoice" style={{ fontWeight: "bolder" }}>
                    Total After Discount / المجموع بعد الخصم:
                  </td>
                  <td className="tdInvoice">
                    {invoiceData?.totalAfterDiscount}
                  </td>
                </tr>
              </>
            )}
            {invoiceData?.extravalues &&
              invoiceData?.extravalues.length > 0 &&
              invoiceData?.extravalues.map((item) => (
                <tr>
                  <td className="thInvoice" style={{ fontWeight: "bolder" }}>
                    {item?.text}:
                  </td>
                  <td className="tdInvoice">{item?.value}</td>
                </tr>
              ))}

            <tr>
              <td className="tdInvoice" style={{ fontWeight: "bolder" }}>
                Vat{" "}
                {AppContext?.userInfo?.vat != null
                  ? AppContext?.userInfo?.vat
                  : 0}
                % /الضريبية :
              </td>
              <td className="tdInvoice">
                {invoiceData?.invoiceproducts
                  ?.map((item) => {
                    const vat = +item?.vat;
                    return vat;
                  })
                  .reduce((accumulator, value) => {
                    return +accumulator + +value;
                  }, 0)
                  .toFixed(2)}
              </td>
            </tr>
            <tr>
              <td className="tdInvoice" style={{ fontWeight: "bolder" }}>
                Net Amount / المبلغ المستحق:
              </td>
              {/* <td className="tdInvoice">{invoiceData?.totalAfterVat}</td> */}
              <td className="tdInvoice">{netAmount}</td>
            </tr>
          </table>
          {flag === false && (
            <img
              className="vat-qr"
              src={invoiceData?.qr}
              style={{ objectFit: "contain" }}
            />
          )}
        </div>
        {(flag === false || flag === "purchases") && (
          <>
            <p style={{ marginTop: flag === false && "unset" }}>
              Saudi Riyals-ريال سعودي
            </p>
            <p style={{ marginBottom: "unset" }}>{userData?.address}</p>
          </>
        )}
      </div>
      {/* {flag === true && ( */}
      {invoiceData?.bank_iban != null && 
      <table
        style={{
          margin: "auto",
          fontSize: "14px",
          width: "55%",
          border: "1px solid black",
        }}
      >
        <tr>
          {/* <td
              style={{
                fontWeight: "bolder",
                borderRight: "1px solid black",
                padding: "8px",
                width: "18%",
              }}
            >
              ايبان البنك\ Bank IBAN
            </td> */}
          <td style={{ padding: "25px"}}>{invoiceData?.bank_iban}</td>
        </tr>
      </table>
      }
      {/* )} */}
    </Box>
  );
});
export default InvoiceHtml;
