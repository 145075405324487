import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Login from "../Pages/Login";
import Dashboard from "../Pages/Dashboard";
import ForgetPassword from "../Pages/ForgetPassword";
import ResetPassword from "../Pages/ResetPassword";

function Main() {
  const token = localStorage.getItem("p_u_t");
  return (
    <>
      <Routes>
        {!token ? (
          <>
            <Route path="/" element={<Login />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
            <Route path="/password-reset" element={<ResetPassword />} />
            <Route path="*" element={<Navigate to={"/"} />} />
          </>
        ) : (
          <>
            <Route path="/*" element={<Dashboard />} />
            
            <Route path="/" element={<Navigate to={"/invoices"} />} />
          </>
        )}
      </Routes>
    </>
  );
}

export default Main;
