import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Request from "../Utils/Request/Request";
import { useState, useContext, useEffect } from "react";
import { AppStorage } from "../Utils/Context/AppContext";
import { showToast } from "../Utils/index";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import DeleteModal from "./DeleteModal";
import { FormControl, MenuItem, Select, TextField, Typography } from "@mui/material";
import ProductModal from "../modals/ProductModal";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import moment from "moment";
import Loader from "./Loader";
import { useTranslation } from "react-i18next";

function AllProducts() {
  // const [page, setPage] = useState(1);
  const { t, i18n } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openDelModal, setOpenDelModal] = useState(false);

  const OpenDelModal = (user) => {
    setOpenDelModal(true);
    setDelProduct(user);
  };

  const CloseDelModal = () => {
    setOpenDelModal(false);
  };

  const [delProduct, setDelProduct] = useState("");
  const [products, setProducts] = useState([]);
  const [productsToUpdate, setProductsToUpdate] = useState(null);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 7,
  });
    const handleChange = (event, value) => {
      let dupPagination = { ...pagination };
      dupPagination.current = value;
      setPagination(dupPagination);
    };
  const [searchPhrase, setSearchPhrase] = useState("");

  const AppContext = useContext(AppStorage);

  useEffect(() => {
    if(AppContext?.userAuthToken || AppContext?.userAuthToken !== ""){
    getData({ pagination, searchPhrase });
    }
  }, [AppContext?.userAuthToken, pagination?.current]);

  useEffect(() => {
    if (forceUpdate) {
      getData({ pagination, searchPhrase });
    }
  }, [forceUpdate]);

  function getData(params) {
    setLoading(true);
    let paramsData = {};

    paramsData = {
      ...paramsData,
      searchPhrase: params.searchPhrase,
    };

    Request.get(`/getProducts/${params.pagination?.current}`, paramsData)
      .then((result) => {
        console.log("Data: ", result);
        setLoading(false);
        setProducts(result.data["data"]);
        setForceUpdate(false);
        setPagination({
          ...params.pagination,
          total: result.data["totalCount"],
        });
        setSearchPhrase(params.searchPhrase);
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }

  function searchChange(value) {
    getData({ pagination, searchPhrase: value });
  }

  const columns = [
    // { field: "id", headerName: "ID", width: 200, flex: 1 },
    { field: "rowNumber", headerName: `${t('SR-NO.')}`, width: 70 },
    { field: "name", headerName: `${t('Name')}`, width: 200, flex: 1 },
    {
      field: "createdAt",
      headerName: `${t("Created At")}`,
      renderCell: (text) => <span>{moment(text).format("YYYY-MM-DD")}</span>,
      width: 200,
      flex: 1,
    },
    {
      headerName: `${t("Actions")}`,
      flex: 1,
      sortable: false,
      minWidth: 100,
      maxWidth: 100,
      renderCell: (record) => {
        return (
          <Box sx={{ display: "flex", gap: "8px" }}>
            <EditIcon
              onClick={() => UpdateProductModal(record.row)}
              sx={{ cursor: "pointer", color: "#41c1ba" }}
            />
            <DeleteIcon
              sx={{ cursor: "pointer", color: "#365b6d" }}
              onClick={() => OpenDelModal(record.row)}
            />
          </Box>
        );
      },
    },
  ];

  function NewProductModal() {
    setOpen(true);
    setProductsToUpdate(null);
  }

  function UpdateProductModal(product) {
    setOpen(true);
    setProductsToUpdate(product);
  }

  function deleteProduct(product) {
    Request.delete(`/delProduct/${product["id"]}`)
      .then(() => {
        setForceUpdate(true);
        CloseDelModal();
        showToast("success", "Deleted Successfully.");
      })
      .catch((err) => {
        console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }
    // Function to recalculate row numbers
    const updateRowNumbers = () => {
      const productsWithRowNumber = products.map((product, index) => {
        return { ...product, rowNumber: (pagination.current - 1) * 7 + (index + 1) };
      });
      setProducts(productsWithRowNumber);
    };
  
    // Function to handle row deletion
    const handleRowDelete = (selectedRowIds) => {
      const updatedProducts = products.filter(
        (product) => !selectedRowIds.includes(product.id)
      );
      setProducts(updatedProducts);
      updateRowNumbers(); // Recalculate row numbers after deletion
    };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          marginBottom: "8px",
        }}
      >
        <TextField
          type="text"
          autoComplete="off"
          placeholder={t("Search By Product")}
          onChange={(event) => searchChange(event.target.value)}
          sx={{
            width: "250px",
            marginRight: "20px",
            "& .MuiOutlinedInput-input": { height: "0.5em" },
            "& fieldset": {
              borderColor: "#365b6d !important",
            },
          }}
        />

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Button
        className="largeScreen-btn"
          onClick={NewProductModal}
          variant="contained"
          sx={{
            background: "#365b6d",
            "&:hover": { backgroundColor: "#365b6d" },
          }}
        >
          {t("Add New Product")}
        </Button>
        </Box>
        <Button
        className="smallScreen-btn"
          onClick={NewProductModal}
          variant="contained"
          sx={{
            fontSize:"30px",
            background: "#365b6d",
            "&:hover": { backgroundColor: "#365b6d" },
            height:"40px"
          }}
        >
          +
        </Button>
      </Box>
     
        <div style={{ height: "calc(100vh - 235px)", width: "100%" }}>
          <Typography
            variant="h6"
            sx={{ color: "#365b6d", textAlign: "center" }}
          >
            {t("All Products")}
          </Typography>
          {loading ? (
        <Loader />
      ) : (
        <>
          <DataGrid
             rows={products.map((product, index) => ({ ...product, rowNumber: (pagination.current - 1) * 7 + (index + 1) }))}
            columns={columns}
            hideFooter
            disableColumnMenu
            checkboxSelection={false}
            disableSelectionOnClick
            sx={{background:"white"}}
            onSelectionModelChange={(newSelection) => handleRowDelete(newSelection)}
          />

          <Stack spacing={2} marginTop="5px">
            <Pagination
              count={Math.ceil(pagination?.total / 7)}
              page={pagination?.current}
              onChange={handleChange}
            />
          </Stack>
          </>
      )}
        </div>
      {open && (
        <ProductModal
          close={handleClose}
          open={open}
          product={productsToUpdate}
          setForceUpdate={setForceUpdate}
        />
      )}

      {openDelModal && (
        <DeleteModal
          del={deleteProduct}
          currentUser={delProduct}
          openDelModal={openDelModal}
          setDeleteModal={setOpenDelModal}
        />
      )}
    </Box>
  );
}

export default AllProducts;
