import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, TextField, Button, MenuItem, Select } from '@mui/material';

const options = [
  { label: "Company Name", value: "companyName" },
  { label: "Company Number", value: "companyNumber" },
  { label: "Vat Number", value: "vatNumber" },
  { label: "Company Name Ar", value: "companyNameAr" },
  { label: "Company Number Ar", value: "companyNumberAr" },
  { label: "Vat Number Ar", value: "vatNumberAr" },
  { label: "Title - TAX INVOICE", value: "titleTaxInvoice" },
  { label: "User Address", value: "userAddress" },
  { label: "Saudi Riyals-ريال سعودي", value: "saudiRiyals" },
  { label: "Text", value: "text" },
  { label: "Hr---", value: "hr" },
  { label: "Space", value: "space" },
  { label: "Customer Address", value: "customerAddress" },
  { label: "Customer Name", value: "customerName" },
  { label: "Invoice Date", value: "invoiceDate" },
  { label: "Invoice No.", value: "invoiceNo" },
  { label: "Notes", value: "notes" },
  { label: "Notes 2", value: "notes2" },
  { label: "PO No.", value: "poNo" },
  { label: "Payment Terms", value: "paymentTerms" },
  { label: "Vat NO.", value: "vatNo" },
  { label: "Discount", value: "discount" },
  { label: "Net Amount", value: "netAmount" },
  { label: "Total Before Vat", value: "totalBeforeVat" },
  { label: "Total After Discount", value: "totalAfterDiscount" },
  { label: "Vat%", value: "vat" },
];

const TemplateText = ({ value, onChange }) => {
  const handleTextChange = (e, index) => {
    const newValue = [...value];
    newValue[index].label = e.target.value;
    onChange(newValue);
  };

  const handleSelectChange = (e, index) => {
    const newValue = [...value];
    const selectedValue = e.target.value;
    
    newValue[index].value = selectedValue;
    
    // Disable and clear the text field if "Space", "Hr---", or "Text" is selected
    if (["space", "hr", "text", "userAddress"].includes(selectedValue)) {
      newValue[index].label = ""; // Clear the text field
    }
    
    onChange(newValue);
  };

  const addField = () => {
    onChange([...value, { label: '', value: '' }]);
  };

  const removeField = (index) => {
    const newValue = value.filter((_, i) => i !== index);
    onChange(newValue);
  };
  const [boxWidth, setBoxWidth] = useState(0);
  const boxRef = useRef(null);

  useEffect(() => {
    const updateWidth = () => {
      if (boxRef.current) {
        setBoxWidth(boxRef.current.offsetWidth);
      }
    };

    const resizeObserver = new ResizeObserver(updateWidth);
    if (boxRef.current) {
      resizeObserver.observe(boxRef.current);
    }

    // Clean up observer on unmount
    return () => {
      if (boxRef.current) {
        resizeObserver.unobserve(boxRef.current);
      }
    };
  }, []);
  return (
    <Box ref={boxRef} sx={{ width: '100%' }}>
      <Typography variant="body2" mb={1}>Add Text and Select Value</Typography>
      {value.map((item, index) => (
        <Box key={index} mb={2} sx={{ display: 'flex', gap: 2, flexDirection: boxWidth < 400 ? 'column' : 'row' }}>
          <TextField
            label="Custom Text"
            value={item.label}
            onChange={(e) => handleTextChange(e, index)}
            fullWidth
            disabled={["space", "hr", "text", "userAddress"].includes(item.value)} // Disable the text field
          />
          <Select
            value={item.value}
            onChange={(e) => handleSelectChange(e, index)}
            displayEmpty
            fullWidth
          >
            <MenuItem value="" disabled>Select Option</MenuItem>
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <Button sx={{color:"red",fontSize:"20px"}} onClick={() => removeField(index)}>
                X
          </Button>
        </Box>
      ))}
      <Button variant="outlined" onClick={addField}>Add Field</Button>
    </Box>
  );
};

export default TemplateText;
