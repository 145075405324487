import "./App.css";
import * as React from "react";
import { Route, Routes } from "react-router-dom";
import { AppContext } from "./Utils/Context/AppContext";
import Main from "./components/Main";

function App() {

  return (
    <>
      <AppContext>
        <Main/>
      </AppContext>
    </>
  );
}

export default App;
