import { Box } from "@mui/material";
import React from "react";
import Logo from "../images/LOGO.png";
import { AppStorage } from "../Utils/Context/AppContext";
import { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Request from "../Utils/Request/Request";
import { showToast } from "../Utils/index";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MobLogo from "../images/logoCrop.png";
import axios from "../Utils/Request/axios";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const navigate = useNavigate();

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const AppContext = useContext(AppStorage);

  function login(e) {
    e.preventDefault();
    if (username !== "" && password !== "") {
      Request.post("/login", {
        username: username,
        password: password,
      })
        .then((result) => {
          console.log("Data: ", result);
          showToast("success", "Logged in successfully.");
          localStorage.setItem("p_u_t", result.data["accessToken"]);
          AppContext?.setUserToken(result.data["accessToken"]);
          axios.defaults.headers.common.Authorization = `Bearer ${result.data["accessToken"]}`;
          window.location.reload();
        })
        .catch((err) => {
          console.log("Error: ", err);
          showToast("error", err.response.data.message);
        });
      // check for role and send to reports if storeAdmin
    } else {
      console.log("show toast of error msg");
    }
  }

  return (
    <div className="login">
      <div className="MobLogo">
        <img src={MobLogo} alt="" className="MobLogoImg" />
      </div>
      <Box className="container" id="container">
        <div className="form-container log-in-container">
          <form className="form" onSubmit={login}>
            <h1>Login</h1>
            <input
              type="username"
              placeholder="Username"
              onChange={(event) => setUsername(event.target.value)}
            />

            <input
              type={passwordShown ? "text" : "password"}
              placeholder="Password"
              onChange={(event) => setPassword(event.target.value)}
            />
            <FontAwesomeIcon
              icon={passwordShown ? faEye : faEyeSlash}
              className="togglePassword"
              onClick={togglePassword}
            />

            <button type="submit" style={{ marginBottom: "10px" }}>
              Log In
            </button>
            <Link to={"/forget-password"}>
              <p className="passwordForget">Forget Password</p>
            </Link>
          </form>
        </div>
        <div className="overlay-container">
          <div className="overlay">
            <div className="overlay-panel overlay-right">
              <img className="Login-img" src={Logo} alt="" />
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
}

export default Login;
