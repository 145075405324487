import React from 'react';
import { Box, MenuItem, Select, Typography } from '@mui/material';

const options = [
  { text: 'QR', value: 'qr' },
  { text: 'Logo', value: 'logo' }
];

const TemplateText = ({ value, onChange }) => {
  return (
    <Box>
      <Typography variant="body2" mb={1}>Select Image Option</Typography>
      <Select
        fullWidth
        value={value}
        onChange={(e) => onChange(e.target.value)}
        displayEmpty
      >
        <MenuItem value="" disabled>
          Select an option
        </MenuItem>
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.text}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default TemplateText;
